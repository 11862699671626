import React from 'react';
import { List, Avatar } from 'antd';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';

interface Member {
    username: string;
    fullname: string;
    num_choices: number;
}

interface MemberListProps {
    members: Member[];
    onMemberClick: (username: string) => void;
    onGroupClick: () => void;
}

const MemberList: React.FC<MemberListProps> = ({ members, onMemberClick, onGroupClick }) => {
    // Sort members alphabetically
    const sortedMembers = members.sort((a, b) => a.username.localeCompare(b.username));

    // Add "Group Info" as the first item
    const memberList = [{ username: "Group Info", fullname: "Group Info", num_choices: 0, icon: <TeamOutlined /> }, ...sortedMembers];

    const getColor = (num_choices: number): string => {
        if (num_choices >= 400) return '#4CAF50'; // Brighter green
        if (num_choices >= 300 && num_choices < 400) return '#FFC107'; // Vibrant yellow
        return '#F44336'; // Vivid red
    };
    
    return (
        <List
            size="small"
            itemLayout="horizontal"
            dataSource={memberList}
            renderItem={member => (
                <List.Item 
                    onClick={member.username === "Group Info" ? onGroupClick : () => onMemberClick(member.username)} 
                    style={{ cursor: 'pointer' }}
                >
                    <List.Item.Meta
                        
                        avatar={<Avatar icon={member.username === "Group Info" ? <TeamOutlined /> : <UserOutlined />} />}
                        title={<div style={{ color: member.username !== "Group Info" ? getColor(member.num_choices) : 'black', paddingTop: '4px' }}>{member.fullname ? member.fullname : member.username} {member.fullname !== 'Group Info' && `(${member.num_choices})`} </div>} // Pushing the title down
                    />
                </List.Item>
            )}
        />
    );
};

export default MemberList;
