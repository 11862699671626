import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Card, List, Typography, Select, Image, Spin, Alert, Tooltip, Row, Col } from 'antd';
import Slide from './Slide';
import '../../App.css'; // Ensure the styles are imported

const { Title, Paragraph, Text } = Typography;
const { Option, OptGroup } = Select;

interface Recommendation2 {
  rec_id: number,
  rec_title: string,
  rec_description: string,
  rec_image_url: string,
  rec_price: string,
  similarity_score: number,
}

interface GroupRecommendation {
  user_id: number;
  user_name: string;
  recommendations: Recommendation2[];
}

interface ProcessedRecommendation {
  rec_id: number;
  rec_title: string;
  rec_image_url: string,
  rec_price: string,
  average_similarity_score: number;
  user_scores: { [key: string]: number };
}

interface Recommendation {
  title: string;
  description: string;
  url: string;
  similarity_score: number;
  image_url?: string;
  price?: string;
  location?: string;
}

interface BaseRecommendation {
  url: string;
  image_url?: string;
  price?: string;
  description?: string;
}

interface IndividualRecommendation extends BaseRecommendation {
  title: string;
  similarity_score: number;
}

interface GroupRecommendationProcessed extends BaseRecommendation {
  rec_title: string;
  rec_description: string,
  rec_image_url: string,
  rec_price: string,
  average_similarity_score: number;
  user_scores: { [key: string]: number };
}

type RecommendationItem = IndividualRecommendation | GroupRecommendationProcessed;

interface Tag {
  name: string;
  is_private: boolean;
}

const fetchTags = async (): Promise<Tag[]> => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/tags/`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${authToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchGroupRecommendations = async (url: string): Promise<GroupRecommendation[]> => {
  const authToken = localStorage.getItem('authToken');
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${authToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};


const fetchRecommendations = async (category: string): Promise<any> => {
  if (!category) return;

  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  let url = `${backendUrl}api/recommendations/${category}/`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${authToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const ProductSlide: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    fetchTags().then(setTags);
  }, []);

  const privateTags = tags.filter(tag => tag.is_private).sort((a, b) => a.name.localeCompare(b.name));
  const publicTags = tags.filter(tag => !tag.is_private).sort((a, b) => a.name.localeCompare(b.name));

  const processGroupRecommendations = (groupRecs: GroupRecommendation[]): ProcessedRecommendation[] => {
    const recScores: { [key: number]: { totalScore: number, count: number, userScores: { [key: string]: number } } } = {};

    groupRecs.forEach(userRec => {
      userRec.recommendations.forEach(rec => {
        if (!recScores[rec.rec_id]) {
          recScores[rec.rec_id] = { totalScore: 0, count: 0, userScores: {} };
        }
        recScores[rec.rec_id].totalScore += rec.similarity_score;
        recScores[rec.rec_id].count += 1;
        recScores[rec.rec_id].userScores[userRec.user_name] = rec.similarity_score;
      });
    });

    return Object.keys(recScores).map(rec_id => {
      const rec = recScores[parseInt(rec_id)];
      const averageScore = rec.totalScore / rec.count;
      const title = groupRecs.find(userRec => userRec.recommendations.some(r => r.rec_id === parseInt(rec_id)))?.recommendations.find(r => r.rec_id === parseInt(rec_id))?.rec_title || "Unknown Title";
      const description = groupRecs.find(userRec => userRec.recommendations.some(r => r.rec_id === parseInt(rec_id)))?.recommendations.find(r => r.rec_id === parseInt(rec_id))?.rec_description || "Unknown Description";

      const image_url = groupRecs.find(userRec => userRec.recommendations.some(r => r.rec_id === parseInt(rec_id)))?.recommendations.find(r => r.rec_id === parseInt(rec_id))?.rec_image_url || "";
      const price = groupRecs.find(userRec => userRec.recommendations.some(r => r.rec_id === parseInt(rec_id)))?.recommendations.find(r => r.rec_id === parseInt(rec_id))?.rec_price || "";

      return {
        rec_id: parseInt(rec_id),
        rec_title: title,
        rec_description: description,
        rec_image_url: image_url,
        rec_price: price,
        average_similarity_score: isNaN(averageScore) ? 0 : averageScore, // Handle NaN case
        user_scores: rec.userScores
      };
    }).sort((a, b) => b.average_similarity_score - a.average_similarity_score); // Sort by average score
  };

  const { data: rawRecommendations, isLoading } = useQuery<GroupRecommendation[] | Recommendation[]>(
    ['recommendations', selectedCategory, selectedTag],
    () => selectedTag ? 
        fetchGroupRecommendations(`${process.env.REACT_APP_BACKEND_URL}api/group-recommendations/${selectedCategory}/${selectedTag}/`) 
        : fetchRecommendations(selectedCategory),
    { enabled: !!selectedCategory && (!!selectedTag || selectedTag === '') }
  );
  
  // Process raw recommendations if they are group recommendations
  const recommendations: any = selectedTag && Array.isArray(rawRecommendations) ? 
                              processGroupRecommendations(rawRecommendations as GroupRecommendation[]) : rawRecommendations;
  
  // Add a new style for the spinner container
  const spinnerContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px', // Adjust the height as needed for your layout
  };

  const titleStyle: React.CSSProperties = {
    fontSize: '24px', // Bigger font size for the title
    textAlign: 'center', // Center the title text
    fontWeight: 'bold', // Make the title text bold
    padding: '10px',
  };

  return (
    <Slide>
      <Card title={<div style={titleStyle}>Recommendations</div>} bordered={false} style={{ width: '100%', maxWidth: '700px', margin: 'auto', textAlign: 'left' }}>
        <Alert
          closable
          type='info'
          message={(
            <div style={{ fontSize: '18px', fontWeight: 700 }} >
               Affiliate Links to Support Similr.ai
            </div>
          )}
          description={(
            <div style={{ fontSize: '14px' }} >
              <p>
               Click and purchase from any of the affiliate links below to support Similr.ai.
               </p>
               <p>
              Your personal ranking score for the item is on the upper right or select a group to see the group
              average's score.  Hover over the average score to see each member's score.
              All famous people's answers are simulated using AI and they did not answer any questions.
              </p>
              <p>
              Let us know on the next feedback slide if there are any other items you'd like ranked or
              if you would like us to have a separte section to ask focused questions about a specific product
              category such as clothing to create a more targeted product search.
              </p>
            </div>
          )}
        />
        
        <Row>
        <Col xs={24} sm={12}>
          <Title level={5} style={{ marginBottom: '10px' }}>Recommendation Category</Title>
          <Select
            showSearch
            style={{ width: 250, marginBottom: '20px' }}
            placeholder="Select a category"
            optionFilterProp="children"
            onChange={(value) => setSelectedCategory(value)}
            filterOption={(input, option: any) =>
              option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="Books">Books (Amazon)</Option>
            <Option value="Subscription Boxes">Subscription Boxes</Option>
            <Option value="Experiences Boston">Experiences Boston (Viator)</Option>
            <Option value="Experiences Tokyo">Experiences Tokyo (Viator)</Option>
            <Option value="Experiences London">Experiences London (Viator)</Option>
            <Option value="Experiences Paris">Experiences Paris (Viator)</Option>
            <Option value="Experiences New York City">Experiences New York City (Viator)</Option>
            <Option value="Experiences Los Angeles">Experiences Los Angeles (Viator)</Option>
            <Option value="Experiences Montreal">Experiences Montreal (Viator)</Option>
            <Option value="Experiences Dubai">Experiences Dubai (Viator)</Option>
            <Option value="Experiences Miami">Experiences Miami (Viator)</Option>
            <Option value="Experiences Amsterdam">Experiences Amsterdam (Viator)</Option>
            <Option value="Experiences Hamburg">Experiences Hamburg (Viator)</Option>
            <Option value="Experiences Chicago">Experiences Chicago (Viator)</Option>
            <Option value="Experiences Shanghai">Experiences Shanghai (Viator)</Option>
            <Option value="Experiences Key West">Experiences Key West (Viator)</Option>
            <Option value="Experiences Bangkok">Experiences Bangkok (Viator)</Option>
          </Select>
        </Col>

        <Col xs={24} sm={12}>
          <Title level={5} style={{ marginBottom: '10px' }}>View by Average Group Score</Title>
          <Select
            showSearch
            style={{ width: 250, marginBottom: '20px' }}
            placeholder="Select a group"
            defaultValue={""}
            onChange={(value) => setSelectedTag(value)}
          >
            <Option value="">No Group - Your Score</Option>
            <OptGroup label="Private Groups">
              {privateTags.map(tag => (
                <Option key={tag.name} value={tag.name}>{tag.name}</Option>
              ))}
            </OptGroup>
            <OptGroup label="Famous Groups">
              {publicTags.map(tag => (
                <Option key={tag.name} value={tag.name}>{tag.name}</Option>
              ))}
            </OptGroup>
          </Select>
        </Col>
        </Row>

        {isLoading ? (
          <div style={spinnerContainerStyle}>
            <Spin size='large' />
          </div>
        ) : (
          <List
            itemLayout="vertical"
            dataSource={recommendations}
            renderItem={(rec: RecommendationItem) => {
              const isGroupRecommendation = selectedTag && 'average_similarity_score' in rec;

              const title = isGroupRecommendation ? (rec as GroupRecommendationProcessed).rec_title : (rec as IndividualRecommendation).title;
              const description = isGroupRecommendation ? (rec as GroupRecommendationProcessed).rec_description : (rec as IndividualRecommendation).description;
              const similarityScore = isGroupRecommendation ? (rec as GroupRecommendationProcessed).average_similarity_score : (rec as IndividualRecommendation).similarity_score;
              
              const imageUrl = isGroupRecommendation ? (rec as GroupRecommendationProcessed).rec_image_url : (rec as IndividualRecommendation).image_url;
              const price = isGroupRecommendation ? (rec as GroupRecommendationProcessed).rec_price : (rec as IndividualRecommendation).price;
              
              const rec2 = (rec as GroupRecommendationProcessed)

              const userScoresTooltip = rec2.user_scores ? (
                <div>
                  {Object.entries(rec2.user_scores).map(([user, score]) => (
                    <div key={user}>
                      {user}: {(score * 1000).toFixed(0)}
                    </div>
                  ))}
                </div>
              ) : (
                <div>Your Score</div>
              );

              return (
                <List.Item>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Title level={5} style={{ marginTop: 10, marginBottom: 10 }}>
                      <a href={rec.url} className="link-hover-effect" target="_blank" rel="noopener noreferrer">
                        {title}
                      </a>
                    </Title>
                    <Tooltip title={userScoresTooltip}>
                      <Text style={{ fontSize: '16px', fontWeight: 500, whiteSpace: 'nowrap' }}>
                        {(similarityScore * 1000).toFixed(0)}
                      </Text>
                    </Tooltip>
                  </div>
                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <div style={{ marginRight: '30px' }}>
                      {imageUrl && (
                        <Image
                          src={imageUrl}
                          width={150} // Set image width
                          alt={title}
                          style={{
                            objectFit: 'cover',
                            borderRadius: '8px', // Rounded edges
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' // Optional: adds a subtle shadow
                          }}
                        />
                      )}
                      {price && (
                        <Paragraph style={{ marginTop: '10px', fontSize: '15px', fontWeight: 500 }}>
                          From {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(price))}
                        </Paragraph>
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      <Paragraph>{description}</Paragraph>
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
        )}
      </Card>
    </Slide>
  );
};

export default ProductSlide;
