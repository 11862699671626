import React from 'react';
import { Card } from 'antd';

const AboutGroups = () => {
    return (
        <Card bordered={false} style={{ width: '1000px', margin: 'auto', overflow: 'hidden' }}>
            <h2>About Groups</h2>
            <p>
                Groups in our platform offer a unique way to share your personality, career, relationship, and
                other AI-generated insights with a select audience. Whether it's family, friends, relationship,
                or marriage groups, you control who sees your information.
            </p>
            
            <h3>Types of Groups</h3>
            <ul>
                <li><strong>Family:</strong> Share your insights with family members.</li>
                <li><strong>Friends:</strong> Connect with friends and share fun insights.</li>
                <li><strong>Relationship:</strong> Deepen your bond by sharing personal insights.</li>
                <li><strong>Marriage:</strong> Foster understanding and growth in your marriage.</li>
            </ul>

            <h3>Benefits of Groups</h3>
            <p>By creating groups, you can:</p>
            <ul>
                <li>Control who has access to your AI-generated content.</li>
                <li>Share custom AI generation content with group members.</li>
                <li>Gain unique insights by combining group members' data for AI-generated advice and suggestions.</li>
                <li>Enhance understanding and connection within your personal networks.</li>
            </ul>

            <p>Explore the potential of groups to maximize the benefits of our AI-driven insights and build stronger connections with those who matter most.</p>
        </Card>
    );
};

export default AboutGroups;
