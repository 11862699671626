import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Button } from 'antd';

// Use the environment variable for the Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

interface WrappedCheckoutComponentProps {
    priceId: string;
}

interface CheckoutComponentProps {
    priceId: string;
}

const CheckoutComponent: React.FC<CheckoutComponentProps> = ({ priceId }) => {    
    const stripe = useStripe();

    const handleCheckout = async () => {
        const authToken = localStorage.getItem('authToken');
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${backendUrl}api/create-checkout-session/`, {
            method: "POST",
            headers: {
                'Authorization': `Token ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ price_id: priceId }),
        });
        const session = await response.json();
        const result = await stripe?.redirectToCheckout({
            sessionId: session.sessionId,
        });
        if (result?.error) {
            // Handle error
        }
    };

    return (
        <Button style={{ width: '100%' }} type="primary" onClick={handleCheckout}>
            Buy Now!
        </Button>
    );
};

const WrappedCheckoutComponent: React.FC<WrappedCheckoutComponentProps> = ({ priceId }) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutComponent priceId={priceId}/>
        </Elements>
    );
};

export default WrappedCheckoutComponent;
