import React, { useState } from 'react';
import { Card, Modal, Spin, message, Image, Table } from 'antd';
import { useQuery } from 'react-query';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';


interface OverviewPair {
    username: string;
    overviewId: number;
    overview_type: string;
    headline1: string;
}

interface AIContent {
    id: number;
    user: string; // Username of the user who created the content
    content_type: string; // Type of the content, e.g., 'image' or 'writeup'
    content_url: string | null; // URL for images
    content_text: string | null; // Text for writeups
    parameters: any; // Parameters used to generate the content
    created_at: string; // Creation date and time
    shared_with_groups: string[]; // Names of the groups with which the content is shared
}

const fetchGroupSharedContent = async (groupName: string): Promise<AIContent[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/groups/shared-content/?tag=${encodeURIComponent(groupName)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch shared content');
    }
    return response.json();
};

const GroupAISection: React.FC<{ groupName: string }> = ({ groupName }) => {
    const { data: sharedContent, isLoading, isError } = useQuery(['groupSharedContent', groupName], () => fetchGroupSharedContent(groupName));
    const [selectedContent, setSelectedContent] = useState<AIContent | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleModalCancel = () => {
        setIsModalVisible(false);
        setSelectedContent(null);
    };

    // Function to format the userOverviewPairs data for display
    const formatUserOverviewPairs = (pairs: OverviewPair[]): string => {
        return pairs.map(pair => `${pair.username} (${pair.overview_type})`).join(', ');
    };

    const formatCreatedAt = (dateString: string) => {
        return moment(dateString).format('ddd MM/DD/YYYY h:mm A'); // Add day of the week
    };
        
    const columns = [
        {
            title: 'Instructions',
            dataIndex: 'parameters',
            key: 'instructions',
            render: (parameters: any) => parameters.instructions,
            sorter: (a: AIContent, b: AIContent) => a.parameters.instructions.localeCompare(b.parameters.instructions),
        },
        {
            title: 'Preview',
            dataIndex: 'content_url',
            key: 'preview',
            render: (text: string, record: AIContent) => {
                if (record.content_type === 'image' && record.content_url) {
                    return <Image preview={false} src={record.content_url} alt="Preview" width={50} height={50} style={{ objectFit: 'cover' }} />;
                }
                return record.content_text ? record.content_text.substring(0, 50) + '...' : null;
            },
            sorter: (a: AIContent, b: AIContent) => a.content_type.localeCompare(b.content_type),
        },
        {
            title: 'Write ups used',
            dataIndex: 'parameters',
            key: 'writeUpsUsed',
            render: (parameters: any) => formatUserOverviewPairs(parameters.userOverviewPairs),
            sorter: (a: AIContent, b: AIContent) => a.parameters.userOverviewPairs.length - b.parameters.userOverviewPairs.length,
        },
        {
            title: 'Created by at',
            key: 'createdByAt',
            render: (text: string, record: AIContent) => `${record.user} at ${formatCreatedAt(record.created_at)}`,
            sorter: (a: AIContent, b: AIContent) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(), // Adjust for descending order
            defaultSortOrder: 'ascend' as const, // Default sorting order
        },
    ];
    
    if (isLoading) {
        return <Spin size="large" />;
    }

    if (isError) {
        message.error('Error loading shared content');
        return null;
    }
    
    // Inline style for cursor change on row hover
    const rowStyle = { cursor: 'pointer' };

    return (
        <>
            <Table 
                columns={columns} 
                dataSource={sharedContent} 
                rowKey="id"
                onRow={(record, rowIndex) => ({
                    onClick: event => {
                        setSelectedContent(record);
                        setIsModalVisible(true);
                    },
                    style: rowStyle, // Applying the cursor style to each row
                })}
            />
            {selectedContent && (
                  <Modal
                    title={selectedContent.parameters.instructions}
                    visible={isModalVisible}
                    onCancel={handleModalCancel}
                    footer={null}
                    width={700}
                >
                    <p><em>Created by <strong>{selectedContent.user}</strong> at {selectedContent.created_at}</em></p>
                    {selectedContent.content_type === 'image' && selectedContent.content_url && (
                        <Image src={selectedContent.content_url} alt="Custom Image" style={{ maxWidth: '100%' }} />
                    )}
                    {selectedContent.content_type !== 'image' && selectedContent.content_text && (
                        <Card bordered={false} bodyStyle={{ paddingTop: 0 }}>
                            <ReactMarkdown>{selectedContent.content_text}</ReactMarkdown>
                        </Card>
                    )}
                </Modal>
            )}
        </>
    );
};

export default GroupAISection;