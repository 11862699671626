import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, Layout, Menu } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { AuthContext } from '../AuthContext';

const { Header } = Layout;

const HeaderComponent: React.FC = () => {
    const { isLoggedIn, username, name, credit_balance, logout } = useContext(AuthContext);
    const location = useLocation();

    // State to manage the selected keys for the menu
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        // Function to update selected keys based on the current path
        const updateSelectedKeys = () => {
            const pathSegments = location.pathname.split('/').filter(Boolean);
            const basePath = '/' + (pathSegments.length > 0 ? pathSegments[0] : '');
            setSelectedKeys([basePath]);
        };

        // Call the function to update keys
        updateSelectedKeys();
    }, [location.pathname]); // Dependency array with location.pathname

    const accountMenu = (
        <Menu
            theme="dark"
            mode="vertical"
            style={{ 
                lineHeight: '64px', 
                border: 0, 
                background: '#001529' // Assuming you're using the default dark theme color
            }}>
            <Menu.Item key="/account" icon={<UserOutlined />} style={{ color: 'white' }}>
                <Link to="/account">Account</Link>
            </Menu.Item>
            <Menu.Item key="/logout" icon={<LogoutOutlined />} style={{ color: 'white' }} onClick={logout}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 1 }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
                <img src="/similrailogo32.png" alt="Logo" style={{ height: '32px', marginRight: '10px' }} />
                <span style={{ color: 'lightgray', fontSize: '24px', fontWeight: '500' }}>Similr.ai</span>
            </Link>

            <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys={selectedKeys}
                style={{ lineHeight: '64px', flex: 1, justifyContent: 'center', fontSize: '16px' }}>
                {/* Navigation Links */}
                {isLoggedIn && (
                    <>
                        <Menu.Item key="/rapidfire">
                            <Link to="/rapidfire">Questions</Link>
                        </Menu.Item>
                        <Menu.Item key="/results">
                            <Link to="/results">Results</Link>
                        </Menu.Item>
                        <Menu.Item key="/groups">
                            <Link to="/groups">Groups</Link>
                        </Menu.Item>
                        <Menu.Item key="/custom-gen">
                            <Link to="/custom-gen">Custom AI Gen</Link>
                        </Menu.Item>
                        <Menu.Item key="/pricing">
                            <Link to="/pricing">Buy Credits</Link>
                        </Menu.Item>
                        <Menu.Item key="/howitworks">
                            <Link to="/howitworks">How It Works</Link>
                        </Menu.Item>
                    </>
                )}
            </Menu>

            <div>
                {isLoggedIn ? (
                     <Dropdown overlay={accountMenu} trigger={['hover']}>
                        <button
                            onClick={e => e.preventDefault()}
                            style={{ 
                                color: 'white', 
                                background: 'none', 
                                border: 'none', 
                                cursor: 'pointer',
                                padding: 0,
                                marginLeft: '100px',
                                fontSize: 'inherit' // Keep font styles consistent with surrounding text
                            }}>
                            {name ? name : username} ({credit_balance} Credits) <UserOutlined />
                        </button>
                    </Dropdown>
                ) : (
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        style={{ lineHeight: '64px', border: 0, background: 'none', fontSize: '16px' }}
                    >
                        <Menu.Item key="/login">
                            <Link to="/login" style={{ display: 'block', width: '100%' }} >Login</Link>
                        </Menu.Item>
                        <Menu.Item key="/signup">
                            <Link to="/signup" style={{ display: 'block', width: '100%' }} >Signup</Link>
                        </Menu.Item>
                    </Menu>
                )}
            </div>
        </Header>
    );
};

export default HeaderComponent;  
