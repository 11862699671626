import React, { useState, useEffect } from 'react';
import { Modal, Select, Button, message } from 'antd';
import { useQuery, useQueryClient } from 'react-query';

const { Option } = Select;

interface Group {
    name: string;
}

const fetchShareableGroups = async (contentId: number): Promise<Group[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/groups/shareable-groups/?content_id=${contentId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const shareContentWithGroup = async (contentId: number, groupName: string) => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/groups/share-content/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            content_id: contentId,
            group_name: groupName,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to share content');
    }
    return response.json();
};

interface ShareContentModalProps {
    contentId: number;
    isVisible: boolean;
    onClose: () => void;
}

const ShareContentModal: React.FC<ShareContentModalProps> = ({ contentId, isVisible, onClose }) => {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const { data: groups, isLoading } = useQuery(['shareableGroups', contentId], () => fetchShareableGroups(contentId), {
        enabled: isVisible
    });
    
    const queryClient = useQueryClient();

    useEffect(() => {
        // Reset selected group when modal is closed
        if (!isVisible) setSelectedGroup(null);
    }, [isVisible]);

    const handleShare = async () => {
        if (!selectedGroup) {
            message.warning('Please select a group to share with.');
            return;
        }
        try {
            await shareContentWithGroup(contentId, selectedGroup);
            message.success('Content shared successfully.');
            queryClient.invalidateQueries('customGensList');
            onClose();
        } catch (error: any) {
            message.error(`Failed to share content: ${error.message}`);
        }
    };

    return (
        <Modal
            title="Share Content"
            visible={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleShare}>
                    Share with Group
                </Button>,
            ]}
        >
            <Select
                placeholder="Select a group"
                loading={isLoading}
                value={selectedGroup}
                onChange={setSelectedGroup}
                style={{ width: '100%' }}
            >
                {groups?.map(group => (
                    <Option key={group.name} value={group.name}>{group.name}</Option>
                ))}
            </Select>
        </Modal>
    );
};

export default ShareContentModal;
