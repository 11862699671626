import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Card, Spin, Image, Col, Row, Select, Popover } from 'antd';
import Slide from './Slide';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

interface Overview {
    id: string;
    num_choices: string;
    title: string;
    model_name: string;
    new_image: boolean;
}

interface OverviewSlideProps {
    overviewType: string;
    title: string;
}

interface ResultsOverview {
    headline1?: string;
    headline2?: string;
    headline3?: string;
    paragraph1?: string;
    paragraph2?: string;
    image_url?: string;
    message?: string;  // For handling loading or error messages
    isLoadingOverview?: boolean; // For handling loading state
    model_name: string;
    new_image: boolean;
}

interface FetchOverviewParams {
    overviewId: string; // Assuming the ID is a string, adjust the type if necessary
    overviewType: string;
}

async function fetchOverview(params: FetchOverviewParams): Promise<ResultsOverview> {
    const { overviewId, overviewType } = params;
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    // Construct the URL with query parameters
    const url = new URL(`${backendUrl}api/results-overview/`);
    url.searchParams.append('id', overviewId);
    url.searchParams.append('type', overviewType);

    const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
}

// Define fetch function outside with correct type definition
async function fetchAllOverviews(overviewType: string): Promise<Overview[]> {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/all-results-overviews/?type=${overviewType}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
}

const OverviewSlide: React.FC<OverviewSlideProps> = ({ overviewType, title }) => {
    const [selectedOverview, setSelectedOverview] = useState<string>('');
    const queryClient = useQueryClient();

    // Fetch all available overviews    
    const { data: allOverviews } = useQuery<Overview[]>(['allOverviews', overviewType], () => fetchAllOverviews(overviewType), {
        onSuccess: (data) => {
            if (data && data.length > 0 && selectedOverview === '') {
                setSelectedOverview(data[0].id);
            }
        }
    });

    const { data } = useQuery(
        ['resultsOverview', selectedOverview],
        () => fetchOverview({ overviewId: selectedOverview, overviewType }),
        {
            enabled: !!selectedOverview, // Only run if selectedOverview is not empty
            refetchInterval: (data) => (data?.isLoadingOverview ? 5000 : false),
            refetchIntervalInBackground: true,
            onSuccess: (data) => {
                if (data && !data.isLoadingOverview && data.headline1) {
                    queryClient.invalidateQueries(['allOverviews', overviewType]);
                }
            },
        }
    );

    const selectExtra = (overview: Overview) => {
        let modelNameDisplay = overview.model_name.includes("gpt-4") ? "GPT-4" : 'GPT-3.5';
        let newImageDisplay = overview.new_image && overviewType !== "dating_profile" ? "Unique Image" : "";
        
        // Combine both strings, separating them with a colon only if both are present
        let displayText = [modelNameDisplay, newImageDisplay].filter(text => text).join(" : ");
    
        return displayText ? `(${displayText})` : "";
    }
        
    const titleStyle: React.CSSProperties = {
        fontSize: '24px', // Bigger font size for the title
        textAlign: 'center', // Center the title text
        fontWeight: 'bold', // Make the title text bold
        padding: '20px',
    };

    const imageStyle: React.CSSProperties = { width: '300px', height: '300px', borderRadius: '8px' };

    const iconStyle: React.CSSProperties = {
        fontSize: '20px',
        cursor: 'pointer',
        position: 'relative',
        left: '-10px', // Move to the left
        top: '5px',  // Move down slightly
    };

    const popoverContent = (
        <div style={{ maxWidth: '500px'}}>
            <p><strong>Want a new Unique Image?</strong></p>
            <p>
                Click on "Custom AI Gen" menu item above to generate new images using this write up.
                You can also customize the image instructions to get a unique image more to your liking.
            </p>
        </div>
    );
    
    return (
        <Slide>
             <Card title={<div style={titleStyle}>{title}</div>} bordered={false} style={{ width: '100%', maxWidth: '800px', margin: 'auto' }}>
                {data && !data.isLoadingOverview && allOverviews && allOverviews.length > 0 && (
                    <Row gutter={16} style={{ marginBottom: '20px' }}>
                        <Col span={22}>
                            <Select 
                                value={selectedOverview} 
                                onChange={setSelectedOverview} 
                                style={{ width: '100%' }}
                            >
                                {allOverviews.map((overview: Overview) => (
                                    <Option key={overview.id} value={overview.id}>{overview.num_choices}: {overview.title} {selectExtra(overview)}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            <Popover content={popoverContent}>
                                <QuestionCircleOutlined style={iconStyle} />
                            </Popover>
                        </Col>
                    </Row>
                )}
                {!data?.isLoadingOverview && data?.headline1 && (
                    <Row align="top">
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <h2 style={{ margin: '0' }}>{data.headline1}</h2>
                            <h4 style={{ margin: '5px 0' }}>{data.headline2}</h4>
                            <h4 style={{ marginBottom: '15px', marginTop: '0' }}>{data.headline3}</h4>
                            {data.image_url && <Image src={data.image_url} alt="Overview Image" style={imageStyle} />}
                        </Col>
                        <Col span={24}>
                            <p style={{ textAlign: 'left', fontSize: '15px' }}>{data.paragraph1}</p>
                        </Col>
                        <Col span={24}>
                            <p style={{ textAlign: 'left', fontSize: '15px' }}>{data.paragraph2}</p>
                        </Col>
                    </Row>
                )}
                {(data && data.isLoadingOverview) && (
                    <Spin size="large" />
                )}
                {(allOverviews && allOverviews.length === 0) && (
                    <div style={{ fontSize: '16px', fontWeight: 500 }} >Never Generated</div>
                )}
            </Card>
        </Slide>
    );
};

export default OverviewSlide;