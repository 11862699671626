import React, { useContext, useEffect, useState } from 'react';
import { Card, Spin, Row, Col, Button, Input, Select, message, Image, List } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import ReactMarkdown from 'react-markdown';
import { AuthContext } from '../../AuthContext';
import SuggestionModal from './SuggestionModal';

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const overviewTypeMapping: { [key: string]: string } = {
    animal: "Animal",
    dnd: "D&D Character",
    dating_profile: "Dating Profile",
    career: "Career",
    matchmaker: "Matchmaker",
    personality: "Personality"
};

interface Template {
    id: number;
    name: string;
    instructions: string;
}

interface User {
    username: string;
    fullname: string;
    num_choices: number;
    is_famous: boolean
}

interface UserOverviewPair {
    username: string;
    fullname: string;
    overview: Overview;
}

interface Overview {
    id: string;
    overview_type: string;
    headline1: string;
}
  
const fetchUsernames = async (): Promise<User[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/usernames/?include_privates=true`, {
        method: 'GET',
        headers: {
        'Authorization': `Token ${authToken}`,
        'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const fetchUserOverviews = async (username: string): Promise<Overview[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/user-overviews/?username=${encodeURIComponent(username)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const CreateCustomGen: React.FC = () => {
    const { updateCreditBalance } = useContext(AuthContext);

    const queryClient = useQueryClient();

    // Change from a single string to an array of strings
    const [currentOverviews, setCurrentOverviews] = useState<string[]>([]);

    const [selectedUserOverviews, setSelectedUserOverviews] = useState<Overview[]>([]);
    const [userOverviewPairs, setUserOverviewPairs] = useState<UserOverviewPair[]>([]);
    const [instructions, setInstructions] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [writeUp, setWriteUp] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const loggedInUsername : string = localStorage.getItem('username') || '';
    const { data: usernames } = useQuery('usernames', fetchUsernames);
    const [currentUsername, setCurrentUsername] = useState<string>(loggedInUsername);

    // Fetch user overviews when the component mounts or currentUsername changes
    useEffect(() => {
        if (currentUsername) {
            handleUserSelect(currentUsername);
        }
    }, [currentUsername]);

    // Group the users into famous and non-famous
    const famousUsers = usernames?.filter(user => user.is_famous).sort((a, b) => a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())) || [];
    const nonFamousUsers = usernames?.filter(user => !user.is_famous).sort((a, b) => a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())) || [];

    const handleUserSelect = async (username: string) => {
        setCurrentUsername(username);
        setCurrentOverviews([]); // Reset current overview when a new user is selected
        try {
            const userOverviews = await fetchUserOverviews(username);
            setSelectedUserOverviews(userOverviews);
        } catch (error) {
            console.error('Failed to fetch user overviews:', error);
            setSelectedUserOverviews([]); // Reset on error
        }
    };

    const handleAddUserOverviewPairs = () => {
        if (currentUsername && currentOverviews.length > 0) {
            const user = usernames?.find(user => user.username === currentUsername);
            if (!user) return; // Early return if user is not found
    
            // Map and then filter in a way that TypeScript understands the type has been narrowed to exclude undefined
            const overviewPairsToAdd: UserOverviewPair[] = currentOverviews.map(overviewId => {
                const overview = selectedUserOverviews.find(ov => ov.id === overviewId);
                // Return null or another marker for entries to filter out
                return overview ? {
                    username: currentUsername,
                    fullname: user.fullname, // Assuming non-null based on early return
                    overview: overview
                } : null; // Use null as a marker for filtering
            }).filter((item): item is UserOverviewPair => item !== null); // TypeScript now understands the array does not include undefined or null after filtering
    
            setUserOverviewPairs(prev => [...prev, ...overviewPairsToAdd]);
        } else {
            message.error("Please select a user and at least one overview type");
        }
    };
    

    const handleTemplateSelect = (template: Template) => {
        // Use the template data to populate your form
        // For example:
        setInstructions(template.instructions);
        // You may need to handle more fields depending on your template structure
      };

    const handleRemoveUserOverviewPair = (index: number) => {
        setUserOverviewPairs(prev => prev.filter((_, i) => i !== index));
    };

    const pollForContentStatus = async (contentId: string, contentType: string) => {
        const checkStatus = async () => {
            const authToken = localStorage.getItem('authToken');
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            
            const response = await fetch(`${backendUrl}api/check-content-status/${contentId}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error('Failed to check content status');
            }
            
            const data = await response.json();
            if (data.status === 'completed') {
                if (contentType === 'image') {
                    setImageUrl(data.content_url); // Make sure data structure aligns with your API response
                    setWriteUp('');
                } else if (contentType === 'writeup' || contentType === 'writeup-gpt4') {
                    setWriteUp(data.content_text); // Adjust based on actual response structure
                    setImageUrl('');
                }
                message.success(`${contentType} generated successfully! Saved in Your Content.`);
                setLoading(false);
                clearInterval(intervalId); // Make sure you define intervalId correctly to clear this interval
                await updateCreditBalance();
                queryClient.invalidateQueries('customGensList');
            }
            // Optionally, handle different states like 'failed'
        };
    
        const intervalId = setInterval(checkStatus, 5000); // Poll every 5 seconds
    };

    const handleGenerateContent = async (contentType: string) => {
        setLoading(true);
        try {
            const authToken = localStorage.getItem('authToken');
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            
            const transformedUserOverviewPairs = userOverviewPairs.map(pair => ({
                username: pair.username,
                overviewId: pair.overview.id // Assuming you only need the ID
            }));
            
            const response = await fetch(`${backendUrl}api/custom-content/`, { // Adjust endpoint as necessary
                method: 'POST',
                body: JSON.stringify({
                    userOverviewPairs: transformedUserOverviewPairs,
                    instructions,
                    contentType
                }),
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error('Failed to initiate content generation');
            }
            
            const data = await response.json();
            const contentId = data.content_id; // Adjust based on actual response field
            
            // Now start polling for status
            pollForContentStatus(contentId, contentType);
    
        } catch (error) {
            message.error(`Failed to initiate ${contentType} generation`);
            setLoading(false);
        }
    };
        
    return (
        <>
            <Spin size='large' spinning={loading}>
                <Card
                    bordered={false}
                    style={{ width: '100%', margin: 'auto', overflow: 'hidden' }}
                >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select a user"
                                    value={currentUsername}
                                    onChange={handleUserSelect}
                                >
                                    {nonFamousUsers.length > 0 && (
                                        <OptGroup label="You and Group Members">
                                            {nonFamousUsers.map(user => (
                                                <Option key={user.username} value={user.username}>{user.fullname}</Option>
                                            ))}
                                        </OptGroup>
                                    )}

                                    {famousUsers.length > 0 && (
                                        <OptGroup label="Famous People">
                                            {famousUsers.map(user => (
                                                <Option key={user.username} value={user.username}>{user.fullname}</Option>
                                            ))}
                                        </OptGroup>
                                    )}
                                </Select>
                            </Col>
                            <Col span={10}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select overview types"
                                value={currentOverviews}
                                onChange={setCurrentOverviews}
                                >
                                {selectedUserOverviews
                                    .sort((a, b) => a.overview_type.localeCompare(b.overview_type))
                                    .map(overview => (
                                        <Option key={overview.id} value={overview.id}>
                                        {overviewTypeMapping[overview.overview_type]} - {overview.headline1}
                                        </Option>
                                    ))
                                }
                            </Select>
                            </Col>
                            <Col span={6}>
                                <Button type='primary' onClick={handleAddUserOverviewPairs} style={{ textAlign: 'left' }}>
                                    Add Selected Overviews
                                </Button>
                            </Col>
                        </Row>
                        {/* List to Manage User Overview Pairs */}
                        <List
                            dataSource={userOverviewPairs}
                            renderItem={(item, index) => (
                                <List.Item
                                    actions={[
                                        <Button type="link" onClick={() => handleRemoveUserOverviewPair(index)}>
                                            Remove
                                        </Button>
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={<strong>{item.fullname}</strong>}
                                        description={`${overviewTypeMapping[item.overview.overview_type]} - ${item.overview.headline1}`}
                                    />
                                </List.Item>
                            )}
                            locale={{
                                emptyText: "Please select at least one user write up above.",
                            }}
                            bordered
                            style={{ backgroundColor: '#fafafa', margin: '10px 0', borderRadius: '5px' }}
                        />

                        {/* Instructions Textarea */}
                        <Row style={{ marginTop: '20px' }}>
                            <Col span={24}>
                                <div>
                                    <span style={{ fontSize: '15px', fontWeight: 500 }}>Instructions:</span>
                                    <SuggestionModal onTemplateSelect={handleTemplateSelect} />
                                </div>
                                <TextArea
                                    rows={4}
                                    value={instructions}
                                    onChange={(e) => setInstructions(e.target.value)}
                                    placeholder="Enter specific instructions for the write up or image generation."
                                />
                            </Col>
                        </Row>
                        <Row justify="center" style={{ marginTop: '20px' }}>
                            <Button type="primary" onClick={() => handleGenerateContent('writeup')} style={{ marginRight: '10px' }}>GPT-3.5 Write Up (1 credit)</Button>                        
                            <Button type="primary" onClick={() => handleGenerateContent('writeup-gpt4')} style={{ marginRight: '10px' }}>GPT-4 Write Up (10 credits)</Button>
                            <Button type="primary" onClick={() => handleGenerateContent('image')}>Create Image (20 credits)</Button>
                        </Row>
                        {/* Display Generated Image */}
                        {imageUrl && (
                            <Row justify="center" style={{ paddingTop: '20px', margin: 'auto', width: '80%' }}>
                                <Image src={imageUrl} alt="Custom Image" style={{ maxWidth: '100%' }} />
                            </Row>
                        )}
                        {/* Display Generated Write-Up */}
                        {writeUp && (
                            <Col span={24}>
                                <Card style={{ textAlign: 'left' }} bordered={false}>
                                    <ReactMarkdown className="markdown">{writeUp}</ReactMarkdown>
                                </Card>
                            </Col>
                        )}
                </Card>
            </Spin>
        </>
    );
};

export default CreateCustomGen;