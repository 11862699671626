import React from 'react';
import { Image, Card, Typography, Alert, Row, Col, List } from 'antd';
import TraitsTable from './TraitsTable';
const { Paragraph, Title, Text } = Typography;

const firstListData = [
  { name: 'Chrissy Hawkins (Stranger Things)', score: 0.64 },
  { name: 'Billy Hargrove (Stranger Things)', score: 0.66 },
  { name: 'Draco Malfoy (Harry Potter)', score: 0.66 },
  { name: 'Elaine Benes (Seinfeld)', score: 0.67 },
  { name: 'Marilyn Monroe', score: 0.68 },
  { name: 'Kanye West', score: 0.68 },
  { name: 'Justin Bieber', score: 0.69 },
  { name: 'Erica Sinclair (Stranger Things)', score: 0.69 },
  { name: 'Negan (The Walking Dead)', score: 0.69 },
  { name: 'Johnny Depp', score: 0.69 },
  { name: 'Tiger Woods', score: 0.69 },
  { name: 'Richard Nixon', score: 0.69 },
  { name: 'Jerry Seinfeld (Seinfeld)', score: 0.69 },
  { name: 'Leonardo DiCaprio', score: 0.69 },
];

const secondListData = [
  { name: 'Mahatma Gandhi', score: 0.82 },
  { name: 'Master Yoda', score: 0.79 },
  { name: 'Gandalf', score: 0.79 },
  { name: 'Santa Claus', score: 0.79 },
  { name: 'Mother Teresa', score: 0.79 },
  { name: 'Wonder Woman', score: 0.78 },
  { name: 'Lord Voldemort', score: 0.78 },
  { name: 'Sauron', score: 0.77 },
  { name: 'Batman', score: 0.77 },
  { name: 'Katniss Everdeen (Hunger Games)', score: 0.77 },
  { name: 'Atticus Finch', score: 0.77 },
  { name: 'Sherlock Holmes', score: 0.77 },
  { name: 'Homer Simpson', score: 0.77 },
  { name: 'Cersei Lannister', score: 0.77 },
];

const HowItWorks = () => {

  const titleStyle: React.CSSProperties = {
    fontSize: '28px', 
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '20px',
  };

  const paragraphStyle: React.CSSProperties = {
    textAlign: 'left',
    fontSize: '15px',
    lineHeight: '1.7em'
  };

  return (
    <>
      <Alert
          style={{ fontSize: '14px', maxWidth: '800px', textAlign: 'left', margin: '20px', marginLeft: 'auto', marginRight: 'auto' }}
          type='warning'
          message='This is Experimental AI'
          description={(
            <>
              <p>
                Similr.ai uses OpenAI's state of the art Large Language Models (LLMs) which contains biases inherit in the embeddings.  Results are dependent on the questions
                which were created by OpenAI's GPT4 and tend to contain biases themselves. <a href='https://platform.openai.com/docs/guides/embeddings/limitations-risks' rel="noreferrer" target='_blank'>Open AI Limitations & Risks</a>
              </p>
              <p>
                All famous people are simulated using OpenAI's GPT4 to answer questions.  
                (300-600 questions per person) Their results are dependent
                on GPT4's knowledge of the person and will inherently contain any biases in that knowledge.
              </p>
            </>
          )}
          showIcon
        />
        <Alert
          style={{ fontSize: '14px', maxWidth: '800px', textAlign: 'left', margin: '20px', marginLeft: 'auto', marginRight: 'auto' }}
          type='info'
          message='Similr.ai Feedback'
          description={(
            <>
            <p>
              Questions/Comments/Suggestions - use our <a href="/results/25">feedback form</a>. (login required)
            </p>
            <p>
              Thank you for taking the time to check out Similr.ai.  Hope you enjoy it as much as we've enjoyed building it!
            </p>
            </>
          )}
          showIcon
        />
      <Card title={<div style={titleStyle}>How Similr.ai Works</div>} bordered={false} style={{ width: '100%', maxWidth: '800px', margin: 'auto', textAlign: 'center' }}>
        
        <Title level={3}>The Quick Non-Technical Answer</Title>
          <Paragraph style={paragraphStyle}>
            Everytime you make a choice the AI understands what that choice means.  After making enough choices it has a solid
            understanding of the entirety of all of your choices.  This understanding can then be used to tell you how much of certain
            "things" are in your choices.
          </Paragraph>

          <Title level={3}>The Quick Technical Answer</Title>
          <Paragraph style={paragraphStyle}>
            Similr.ai uses OpenAI's embeddings (text-embedding-ada-002) to create a "user embedding" which represents information from every choice 
            the user made in answering this or that questions.  This user embedding is the simple element wise average of all
            the choices.  A choice is represented by the chosen option's embedding minus the not chosen option's embedding.
          </Paragraph>
          <Paragraph style={paragraphStyle}>
            The user embedding can then be directly compared to any other text embedding.  The more similar the embeddings the
            more of "that" the user had in their choices.
          </Paragraph>

          <Title level={3}>The Long Answer</Title>
          <Paragraph style={paragraphStyle}>
            I'm still surprised how well this works.  (Then again almost a year later I continue to be amazed by GPT4!)  I believe it
            works due to the incredible depth of knowledge that is represented
            in these embeddings.  For example what does it mean when someone chooses Coke over Pepsi or Dog over Cat?  We all
            probably have some mental concept in our heads but its hard to put into words.  The difference embedding likely
            captures this meaning exceptionally well and then when you take all of these choices and average them you get 
            a user embedding that is rich with meaning.
          </Paragraph>
          <Paragraph style={paragraphStyle}>

          </Paragraph>
                    
          <Title style={{ textAlign: 'left' }} level={5}>Testing On Famous People</Title>
          <Paragraph style={paragraphStyle}>
            Since it would be hard to find people to take this test and then know if the results were accurate, I decided
            to have GPT4 pretend to be the famous people and answer the questions as though it was the person.
            I also had it produce a percent probabilty that the user would choose that option.  If the choice was close
            to 50% it was marked as a skip.  It also produced an explanation for the choice.
            This allowed me to go through and spot check the decisions.
          </Paragraph>
          <Paragraph style={paragraphStyle}>
            Here are some examples:
          </Paragraph>

          <Card bordered={false} style={{ textAlign: 'left', maxWidth: 800, margin: '0 auto' }}>
            <Title level={4}>Barack Obama</Title>
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Choice: </Text><Text>85% Left</Text>
              <div>
                <Text strong>Left Choice: </Text>
                <Text>Would you rather lead a team on a volunteer project with specific, impactful goals?</Text>
              </div>
              <div>
                <Text strong>Right Choice: </Text>
                <Text>Would you rather engage in casual conversations at a local cafe's trivia night with a group of acquaintances?</Text>
              </div>
              <Text italic>
                Obama's history of community organization and leadership suggests a preference for leading a team on a project over casual socializing.
              </Text>
            </div>
            
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Choice: </Text><Text>55% Left (Skip)</Text>
              <div>
                <Text strong>Left Choice: </Text>
                <Text>Exploring the depths of the ocean in 'Titanic's tragic love story.</Text>
              </div>
              <div>
                <Text strong>Right Choice: </Text>
                <Text>Witnessing the whimsical and magical world of 'Harry Potter'.</Text>
              </div>
              <Text italic>
                Given Obama's varied tastes in cultural experiences, but with no explicit preference for fantasy, he might lean towards the historical and romantic aspects of 'Titanic'.
              </Text>
            </div>
            
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Choice: </Text><Text>70% Left</Text>
              <div>
                <Text strong>Left Choice: </Text>
                <Text>Indulging in the rich and flavorful cuisine while touring Tokyo, Japan's sprawling metropolis.</Text>
              </div>
              <div>
                <Text strong>Right Choice: </Text>
                <Text>Embarking on an adventurous hiking journey in Switzerland's scenic Alps mountains.</Text>
              </div>
              <Text italic>
                Considering his global diplomatic background and interest in history, Obama may find the cultural and culinary experiences of Tokyo more appealing.
              </Text>
            </div>
          </Card>

          <Card bordered={false} style={{ textAlign: 'left', maxWidth: 800, margin: '0 auto' }}>
            <Title level={4}>Barbie</Title>
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Choice: </Text><Text>85% Left</Text>
              <div>
                <Text strong>Left Choice: </Text>
                <Text>Summer</Text>
              </div>
              <div>
                <Text strong>Right Choice: </Text>
                <Text>Winter</Text>
              </div>
              <Text italic>
                Summer offers more opportunities for outdoor fashion and adventure, which Barbie would enjoy.
              </Text>
            </div>
            
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Choice: </Text><Text>70% Right</Text>
              <div>
                <Text strong>Left Choice: </Text>
                <Text>The grand and sweeping classical compositions of the 18th century</Text>
              </div>
              <div>
                <Text strong>Right Choice: </Text>
                <Text>The innovative electronic explorations in music of the 2020s</Text>
              </div>
              <Text italic>
                The innovative nature of 2020s electronic music fits with Barbie's forward-thinking and adaptive personality.
              </Text>
            </div>
            
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Choice: </Text><Text>90% Left</Text>
              <div>
                <Text strong>Left Choice: </Text>
                <Text>Forging connections seeking meaning in relationships and altruism</Text>
              </div>
              <div>
                <Text strong>Right Choice: </Text>
                <Text>Prioritizing self-reliance and detaching from social bonds</Text>
              </div>
              <Text italic>
                Barbie's commitment to inspiring children suggests a worldview that values connecting with others.
              </Text>
            </div>
          </Card>

          <Title style={{ textAlign: 'left' }} level={5}>Confidence Level On Famous People</Title>
          <Paragraph style={paragraphStyle}>
            I then took the average probabilty from GPT4 of all the questions per person to get an average confidence
            score for each famous person:
          </Paragraph>

          <Row gutter={16}>
            <Col span={12}>
              <List
                size="small"
                header={<div style={{ fontWeight: 700 }} >Least Confident</div>}
                bordered
                dataSource={firstListData}
                renderItem={item => (
                  <List.Item>
                    {item.name} - {Math.round(item.score * 100)}%
                  </List.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <List
                size="small"
                header={<div style={{ fontWeight: 700 }} >Most Confident</div>}
                bordered
                dataSource={secondListData}
                renderItem={item => (
                  <List.Item>
                    {item.name} - {Math.round(item.score * 100)}%
                  </List.Item>
                )}
              />
            </Col>
          </Row>

          <Title style={{ textAlign: 'left' }} level={5}>Similarity Score for User Similarity</Title>
          <Paragraph style={paragraphStyle}>
            Now that we have user embeddings we can compare two users to each other
            using cosine similarity. Cosine similarity gives you values between 1 and -1
            with 1 being exactly the same and -1 exact opposites.
            To make them more human friendly I multiple by 100 and round to the nearest integer.
            This gives nice easy to compare values of 100 to -100.
          </Paragraph>
          <Paragraph style={paragraphStyle}>
            The end result is you can now know exactly how similar:
            <ul>
              <li>Donald Trump is to Jesus: -45</li>
              <li>Steve Jobs is to Elon Musk: 53</li>
              <li>Vecna (Stranger Things) is to Lord Voldemort (Harry Potter): 66</li>
              <li>Arya Stark (Game of Thrones) is to Max Mayfield (Stranger Things): 59</li>
              <li>Mother Teresa is to The Joker (Batman): -63</li>
            </ul>
          </Paragraph>
          <Paragraph style={paragraphStyle}>
            <a href='/similr'>Check out Similarities Here</a> (login required)
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>t-SNE for User Similarity</Title>
          <Paragraph style={paragraphStyle}>
            We can now use t-SNE to plot all the users on a 2D chart.
            t-SNE does an impressive job of compressing these 1,536 dimension vectors into 2 dimensions attempting to keep distances.
            The axis don't matter just how the people group and the distances between people.  The dots are color coded
            for the top General Personality category.
          </Paragraph>
          <Paragraph style={paragraphStyle}>
            All, Real People, Fictional Characters, Stanger Things, (Presidents, Stranger Things, Star Wars)
          </Paragraph>

          <Image.PreviewGroup>
            <Image width={140} src='similrchart2.png' />
            <Image width={140} src='realpeoplechart.png' />
            <Image width={140} src='fictionalpeoplechart.png' />
            <Image width={140} src='strangerthingschart.png' />
            <Image width={140} src='stswpresidentschart.png' />
          </Image.PreviewGroup>

          <Paragraph style={paragraphStyle}>
            <br></br>
            <a href="/similr-chart">Try it out</a> (login required)
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Similarity Score for Text Embedding</Title>
          <Paragraph style={paragraphStyle}>
            Comparing the user embedding to any normal text embedding using cosine similarity tended to produce
            values in the 0.2 to -0.2 values.  So to make them more human friendly, I would multiple by 1000 and round to the nearest integer.
            This gave nice easy to compare values of 200 to -200.  Same as user similarity the higher the values the more similar
            the user embedding is to the text embedding.
          </Paragraph>
          <Paragraph style={paragraphStyle}>

          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Category Rankings</Title>
          <Paragraph style={paragraphStyle}>
            Now that we can generate a score for any text we can create categories and see how each item in the category ranks.
            Personality traits and careers worked well as many of the questions contain relevant information to these.
            I did general and more specific.  You can do pretty much anything
            but the result quality depend heavily on the questions answered and the specific text that is embedded.
          </Paragraph>

          <Row gutter={16}>
      <Col span={12}>
        <div>
          <div className="list-title">Elon Musk</div>
          <ul className="list">
            <li className="list-item">Technology: 182</li>
            <li className="list-item">Engineering: 136</li>
            <li className="list-item">Science and Research: 123</li>
            <li className="list-item">Marketing and Communication: 81</li>
            <li className="list-item">Business and Finance: 73</li>
            <li className="list-item">Healthcare: 73</li>
            <li className="list-item">Legal Profession: 51</li>
            <li className="list-item">Education: 47</li>
            <li className="list-item">Public Service: 24</li>
            <li className="list-item">Arts and Entertainment: -2</li>
          </ul>
        </div>
      </Col>
      <Col span={12}>
        <div>
          <div className="list-title">Marilyn Monroe</div>
          <ul className="list">
            <li className="list-item">Arts and Entertainment: 113</li>
            <li className="list-item">Education: 78</li>
            <li className="list-item">Healthcare: 62</li>
            <li className="list-item">Public Service: 45</li>
            <li className="list-item">Legal Profession: 22</li>
            <li className="list-item">Science and Research: 18</li>
            <li className="list-item">Marketing and Communication: 4</li>
            <li className="list-item">Business and Finance: -42</li>
            <li className="list-item">Engineering: -55</li>
            <li className="list-item">Technology: -56</li>
          </ul>
        </div>
      </Col>
    </Row>

          <Paragraph style={paragraphStyle}>
            <a href='/recommendations'>Check out various categories and how famous people rank</a> (login required)
          </Paragraph>


          <Title style={{ textAlign: 'left' }} level={5}>Word Rankings</Title>
          <Paragraph style={paragraphStyle}>
            How about just simple words?  I took the 10,000 most common words and created embeddings for each one and then
            looked at which words were highly ranked and which ones were lowly ranked for a famous person.  It provides
            an interesting general feel for a person's personality.
          </Paragraph>

          <div className="list-title">Marilyn Monroe</div>
          <Row gutter={16}>
          <Col span={12}>
          <div>
            <div className="list-title">Top Words</div>
            <ul className="list">
              <li className="list-item">romantic: 133</li>
              <li className="list-item">romance: 117</li>
              <li className="list-item">renaissance: 108</li>
              <li className="list-item">literary: 105</li>
              <li className="list-item">poetry: 102</li>
              <li className="list-item">sympathy: 101</li>
              <li className="list-item">loving: 101</li>
              <li className="list-item">charming: 100</li>
              <li className="list-item">artistic: 97</li>
              <li className="list-item">charity: 96</li>
            </ul>
          </div>
          </Col>
          <Col span={12}>
          <div>
            <div className="list-title">Bottom Words</div>
            <ul className="list">
              <li className="list-item">engineer: -64</li>
              <li className="list-item">gaming: -65</li>
              <li className="list-item">stuck: -66</li>
              <li className="list-item">hardware: -69</li>
              <li className="list-item">battlefield: -72</li>
              <li className="list-item">mechanical: -78</li>
              <li className="list-item">survive: -79</li>
              <li className="list-item">survival: -83</li>
              <li className="list-item">tactics: -84</li>
              <li className="list-item">hacker: -87</li>
            </ul>
          </div>
          </Col>
          </Row>

          <div className="list-title">Elon Musk</div>
          <Row gutter={16}>
            <Col span={12}>
              <div>
                <div className="list-title">Top Words</div>
                <ul className="list">
                  <li className="list-item">innovation: 153</li>
                  <li className="list-item">innovative: 140</li>
                  <li className="list-item">initiative: 123</li>
                  <li className="list-item">technological: 120</li>
                  <li className="list-item">frontier: 118</li>
                  <li className="list-item">entrepreneur: 112</li>
                  <li className="list-item">advancement: 108</li>
                  <li className="list-item">project: 107</li>
                  <li className="list-item">engineer: 106</li>
                  <li className="list-item">sprint: 106</li>
                </ul>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <div className="list-title">Bottom Words</div>
                <ul className="list">
                  <li className="list-item">calm: -78</li>
                  <li className="list-item">silent: -81</li>
                  <li className="list-item">charming: -84</li>
                  <li className="list-item">myrtle: -85</li>
                  <li className="list-item">laughing: -85</li>
                  <li className="list-item">relax: -87</li>
                  <li className="list-item">silence: -87</li>
                  <li className="list-item">quiet: -90</li>
                  <li className="list-item">relaxation: -92</li>
                  <li className="list-item">sympathy: -102</li>
                </ul>
              </div>
            </Col>
          </Row>

          <div className="list-title">Homer Jay Simpson</div>
          <Row gutter={16}>
            <Col span={12}>
              <div>
                <div className="list-title">Top Words</div>
                <ul className="list">
                  <li className="list-item">enjoying: 119</li>
                  <li className="list-item">neighborhood: 112</li>
                  <li className="list-item">relax: 103</li>
                  <li className="list-item">entertainment: 102</li>
                  <li className="list-item">enjoy: 102</li>
                  <li className="list-item">comfortable: 100</li>
                  <li className="list-item">comfort: 99</li>
                  <li className="list-item">lounge: 98</li>
                  <li className="list-item">cottage: 98</li>
                  <li className="list-item">friendly: 97</li>
                </ul>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <div className="list-title">Bottom Words</div>
                <ul className="list">
                  <li className="list-item">malpractice: -87</li>
                  <li className="list-item">leadership: -88</li>
                  <li className="list-item">pursuit: -90</li>
                  <li className="list-item">intelligence: -90</li>
                  <li className="list-item">investigation: -92</li>
                  <li className="list-item">studied: -92</li>
                  <li className="list-item">pursue: -94</li>
                  <li className="list-item">scholar: -97</li>
                  <li className="list-item">prophet: -99</li>
                  <li className="list-item">scientist: -100</li>
                </ul>
              </div>
            </Col>
          </Row>

          <div className="list-title">The Joker (Batman)</div>
          <Row gutter={16}>
            <Col span={12}>
              <div>
                <div className="list-title">Top Words</div>
                <ul className="list">
                  <li className="list-item">dare: 108</li>
                  <li className="list-item">adventure: 107</li>
                  <li className="list-item">thriller: 90</li>
                  <li className="list-item">novelty: 89</li>
                  <li className="list-item">frontier: 88</li>
                  <li className="list-item">revolutionary: 87</li>
                  <li className="list-item">playboy: 87</li>
                  <li className="list-item">fantasy: 86</li>
                  <li className="list-item">unauthorized: 85</li>
                  <li className="list-item">deviant: 84</li>
                </ul>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <div className="list-title">Bottom Words</div>
                <ul className="list">
                  <li className="list-item">rest: -99</li>
                  <li className="list-item">maintenance: -100</li>
                  <li className="list-item">beneficial: -102</li>
                  <li className="list-item">comfort: -102</li>
                  <li className="list-item">quiet: -102</li>
                  <li className="list-item">calm: -103</li>
                  <li className="list-item">relax: -105</li>
                  <li className="list-item">relaxation: -113</li>
                  <li className="list-item">gently: -116</li>
                  <li className="list-item">gentle: -137</li>
                </ul>
              </div>
            </Col>
          </Row>
          
          <Paragraph style={paragraphStyle}>
            <a href='/topwords'>Check it out the word rankings for yourself</a> (login required)
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Notable Specific Careers</Title>
          <Paragraph style={paragraphStyle}>
            The top ranked specific careers are very interesting: (out of ~400 career options)
            <ul>
              <li>Gollum - Taxidermist</li>
              <li>James Bond - Counterterrorism Analyst</li>
              <li>Elon Musk - Aerospace Engineer</li>
              <li>Trump - Political Campaign Manager</li>
              <li>Neil deGrasse Tyson and Captain Kirk - Astrobiologist</li>
              <li>Kanye West (198 - highest score) and Paris Hilton - Immersive Experience Director</li>
              <li>Darth Vader - Machine Ethics Advisor (Maybe if he survived at the end?)</li>
              <li>Cosmo Kramer and Marilyn Monroe - Improv Coach</li>
              <li>Arya Stark - Wildland Firefighter</li>
              <li>Ada Lovelace, Stephen Hawking, and Spock - Artificial Intelligence Ethicist</li>
              <li>Britney Spears - Music Therapist (I think this would be a great career move!)</li>
              <li>Captain Jack Sparrow - Underwater Archeologist</li>
              <li>Homer Jay Simpson and Michael Gary Scott - Stand-Up Comedian</li>
              <li>Vecna - Artificial Intelligence Ethicist (Seems like a very bad idea!)</li>
              <li>Tony Soprano - City Solicitor (Sounds about right...)</li>
              <li>Tony Stark - Augmented Reality Designer</li>
              <li>Anna Eleanor Roosevelt and Nelson Mandela - Human Rights Officer</li>
              <li>George Costanza - Cartoonist</li>
              <li>Cersei Lannister - Executive Pastry Chef (Stay out of her kitchen!)</li>
              <li>The Joker - Fashion Designer (Does the Joker have a clothing line?)</li>
              <li>The Rock - Sports Nutritionist</li>
              <li>Katniss Everdeen - Habitat Restoration Ecologist</li>
            </ul>
          </Paragraph>
          <Paragraph style={paragraphStyle}>
            <a href="/results/23">See top results with AI images of various categories for groups of famous people</a> (login required)
          </Paragraph>
          <Title style={{ textAlign: 'left' }} level={5}>Categorizing the Questions</Title>
          <Paragraph style={paragraphStyle}>
            I also played around with categorizing the questions and then only using questions from a category to create
            recommendation scores.  For example one category is Food & Cuisine, then use this user embedding that only
            consists of choices made for Food & Cuisine questions for the recommendations for the Food category.
            It did seem to work better for some categories like food.
          </Paragraph>
          <Paragraph style={paragraphStyle}>
            <a href="/predict-choice">JSON loaded titles/descriptions for custom recs with question categories</a> (login required)
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Similarity to Groups</Title>
          <Paragraph style={paragraphStyle}>
            I (actually GPT4 did it) tagged famous people with various labels in order to group them.
            For example Ada Lovelace was tagged with Historical Figure, Adult, Real Person, Female, Famous, Scientist, Author.
            This now creates grops of people and an average group embedding can be created by taking all of the members
            and averaging their user embeddings together element wise.  This is the "Average Person" that shows up in the t-SNE
            chart and similiarity table.  This group embedding now allows you to compare individuals to groups
            or groups to groups.
          </Paragraph>
          <Paragraph style={paragraphStyle}>
            The General Personality rankings are for the ~160 famous people so a small and unique overall sample
            but the results are still interesting.  There is also <strong>significant selection bias</strong> on
            which famous people were picked.
          </Paragraph>
          </Card>
          
          <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
          }}>
              <div style={{ padding: '40px' }} >
                  <TraitsTable />
              </div>
          </div>

          <Card bordered={false} style={{ width: '100%', maxWidth: '800px', margin: 'auto', textAlign: 'center' }}>
          <Title style={{ textAlign: 'left' }} level={5}>Write ups using LLMs</Title>
          <Paragraph style={paragraphStyle}>
            The ranked results for personality, careers, and similarity to famous people along with some basic profile
            questions (if you answer the questions you will see these - such as age, gender, hair color, etc...)
             are fed into the prompt along with instructions.
            We currently produce 6 reports: personality, career, matchmaking, dating profile, D&D Character, and Animal.
          </Paragraph>
          
          <Title style={{ textAlign: 'left' }} level={5}>Images using DallE-3</Title>
          <Paragraph style={paragraphStyle}>
            The write up results from GPT4 are then fed into DallE-3 to produce an image based on the write up.
            It is really cool to see it go from answering some this or that questions, to a write up, to an
            image representing the person as an animal.  This is one of my favorite animal images and I think it
            did an impressive job on Taylor Swift's animal:
          </Paragraph>

          <Image
              src="animalreport.png"
              alt="Taylor Swift's Animal"
              style={{ width: '100%' }}
          />

          <Title level={3}>Issues</Title>

          <Title style={{ textAlign: 'left' }} level={5}>Issues with Binary Choices, Poor Questions</Title>
          <Paragraph style={paragraphStyle}>
            The questions were created by GPT4 and despite some manual filtering still exhibit some biases toward certain opposite
            options and in many cases the opposites don't make entire sense.  Being more aggressive in skipping questions does help.  There also are alot of questions on environment,
            wellness, and tech.  My guess
            is the poor question quality overall is why so many choices are needed (~500) to get the best results.  Likely with better questions or a
            better method then just a binary choice, less questions would be needed.  Also if you are targetting text embeddings
            from one specific category like clothing you can focus questions on that one area and will need many less questions.
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Self-Reporting</Title>
          <Paragraph style={paragraphStyle}>
            Self-reflection can be hard.  For real people it is likely to end up with results that are closer to who they want to be versus
            who they are.  I come out extroverted but am definitely an introvert in most situations but I do wish
            I was more extroverted.  The extrovert/introvert was one of the most confusing traits.
            It would be interesting to have a close friend/family member answer the questions for you and see how
            similar your user embedding is to your friend's version of your user embedding.
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Issues with Famous People's Results</Title>
          <Paragraph style={paragraphStyle}>
            Having GPT4 generate the answers causes the results to be the general public perception of someone
            or better phrased as the general perception of the training data used.
            If the person was actually taking the test they would definitely have different answers for some of
            questions.
          </Paragraph>

          <Paragraph style={paragraphStyle}>
            A great example of this is Cristiano Ronaldo's top personality trait is Vindictiveness.
            The exact embedded description is
            "A strong desire for revenge, which can fuel ongoing cycles of retaliation and escalate conflicts."
            It's rare for the top trait to be negative.  It's fascinating that this happened since the questions are all
            fairly positive.  If you look it up online there are a lot of stories about him being vindictive so
            somehow this made it into GPT4s answers for him and then showed up in the results.  I doubt this
            would have happened if he actually answered the questions.
          </Paragraph>

          <Paragraph style={paragraphStyle}>
            Ron Weasley from Harry Potter is another interesting one.  A bigger Harry Potter fan then me
            should probably comment on this but it's fascinating that his top specific personality traits are opposite traits.
            I was unable to find another example as dramatic as his. The user embeddings appear to be able to handle the
            fact that you can be introverted and extroverted or in Ron's case he shows both Resentment and Munnanimity,
            Sensitivity and Insensitivity.  It does make sense as in some situations (questions) one trait shows up and in
            other situations the opposite appears.  This demonstrates the depth of knowledge held in the user embeddings.
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Ron Weasley's Top 5 Specific Personality Traits (out of 149)</Title>
          <List
            itemLayout="horizontal"
            dataSource={[
              { title: 'Resentment', description: 'Bitter indignation at having been treated unfairly, which can result in holding grudges and a refusal to forgive.', score: 79 },
              { title: 'Nurturance', description: 'A tendency to care for and encourage the growth or development of someone or something with a supportive and protective manner.', score: 74 },
              { title: 'Munnanimity', description: 'The willingness to forgive offenses readily and to practice understanding and leniency, especially toward a perceived adversary.', score: 73 },
              { title: 'Compassion', description: 'Inclination to understand and alleviate others\' suffering. Compassionate individuals deeply empathize with the struggles of others and are often motivated to help.', score: 70 },
              { title: 'Sensitivity', description: 'A predisposition to experience emotional reactions strongly, to understand others\' feelings deeply, and to be affected by the nuances of emotional situations.', score: 62 },
              { title: 'Insensitivity', description: 'The inability or refusal to be sensitive to the needs or feelings of others, often causing emotional hurt.', score: 60 }
            ]}
            renderItem={item => (
              <List.Item style={{ textAlign: 'left' }}>
                <List.Item.Meta
                  title={<span>{item.score} - {item.title}</span>}
                  description={item.description}
                />
              </List.Item>
            )}
          />

          
          <Title level={3}>Further Research</Title>

          <Title style={{ textAlign: 'left' }} level={5}>Non-binary Choices and Weighted Averages</Title>
          <Paragraph style={paragraphStyle}>
            It would be easy to weight the decision based on the amount of time it took the user to make it.
            Quick decisions tend to be stronger feelings for a choice.
            Another option would be to figure out how to offer multiple choices or offer weighted choices such as
            Strong Left, Lean Left, No Choice, Lean Right, and Strong Right. 
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Supervised Learning with User Embeddings as Input</Title>
          <Paragraph style={paragraphStyle}>
            I think this has great potential.  The user embeddings can be used as inputs to a Deep Learning Model
            and the target can be anything such as age, gender, or mental health issue.   Once trained the model
            can then produce a probabilty or a number guess for someone not in the training set.
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>This or That Search (TOTS)</Title>
          <Paragraph style={paragraphStyle}>
            I think this would work well for certain areas such as
            clothing, hotels, dating sites.  I could see online companies experimenting with this idea.
            Perhaps this might work better with image embeddings instead of text embeddings or perhaps combine
            text and image embeddings if possible.
          </Paragraph>

          <Title style={{ textAlign: 'left' }} level={5}>Image Embeddings</Title>
          <Paragraph style={paragraphStyle}>
            I only used text embeddings but it would be just as easy to use embeddings that represent the image
            and then make the choice the difference of the image embeddings.
          </Paragraph>
          
      </Card>
    </>
  );
};

export default HowItWorks;
