import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Radio, Select, Typography } from 'antd';
import { useQuery } from 'react-query';

const { Option } = Select;
const { Paragraph, Text } = Typography;


interface Template {
  id: number;
  name: string;
  instructions: string;
  template_type: string;
  content_type: string;
  data_needed: any;
}

const fetchTemplates = async (): Promise<Template[]> => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/get-custom-templates/`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${authToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

interface SuggestionModalProps {
  onTemplateSelect: (template: Template) => void;
}

const SuggestionModal: React.FC<SuggestionModalProps> = ({ onTemplateSelect }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { data: templates, isLoading: isTemplatesLoading } = useQuery<Template[]>('templates', fetchTemplates);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);

  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

  useEffect(() => {
    // Initially, set filtered templates to all templates
    setFilteredTemplates(templates || []);
  }, [templates]);

  const handleTemplateChange = (templateId: number) => {
    const template = filteredTemplates.find(t => t.id === templateId);
    setSelectedTemplate(template || null);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const selectedTemplateId = form.getFieldValue('templateId');
    const selectedTemplate = filteredTemplates.find((t: Template) => t.id === selectedTemplateId);
    if (selectedTemplate) {
      onTemplateSelect(selectedTemplate);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFilterChange = () => {
    const typeFilter = form.getFieldValue('templateType');
    const contentFilter = form.getFieldValue('contentType');
    const filtered = templates?.filter(template => 
      (!typeFilter || template.template_type === typeFilter) &&
      (!contentFilter || template.content_type === contentFilter)
    );
    setFilteredTemplates(filtered || []);
  };

  return (
    <>
      <Button type="link" onClick={showModal}>
        Ideas
      </Button>
      <Modal title="Instruction Ideas" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical" onValuesChange={onFilterChange}>
          <Form.Item name="templateType" label="Template Type">
            <Radio.Group>
              <Radio value="Self">Self</Radio>
              <Radio value="Individual">Individual</Radio>
              <Radio value="Family">Family</Radio>
              <Radio value="Friends">Friends</Radio>
              <Radio value="Relationship">Relationship</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="contentType" label="Content Type">
            <Radio.Group>
              <Radio value="image">Image</Radio>
              <Radio value="writeup">Writeup</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="templateId" label="Select a Template">
            <Select loading={isTemplatesLoading} onChange={handleTemplateChange}>
              {filteredTemplates.map((template: Template) => (
                <Option key={template.id} value={template.id}>{template.name}</Option>
              ))}
            </Select>
          </Form.Item>
          {selectedTemplate && (
            <div>
              <Paragraph>
                <Text strong>Instructions: </Text>{selectedTemplate.instructions}
              </Paragraph>
              <Paragraph>
                <Text strong>Suggested Write Ups: </Text>
                {selectedTemplate.data_needed.overview_types.join(', ')}
              </Paragraph>
            </div>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default SuggestionModal;
