import React, { useEffect, useState } from 'react';
import { Select, Card, Spin, Table } from 'antd';
import { useQuery } from 'react-query';
import '../../App.css';

interface Member {
  username: string;
  fullname: string;
}

interface SimilrScore {
  name: string;
  score: number;
}

interface SimilrSectionProps {
  members: Member[];
}

const fetchSimilarityScores = async (username1: string, username2: string) => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    
    const response = await fetch(`${backendUrl}api/groups/similarity-scores/?username1=${encodeURIComponent(username1)}&username2=${encodeURIComponent(username2)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch similarity scores');
    }
    return response.json();
};

const SimilrSection: React.FC<SimilrSectionProps> = ({ members }) => {
    const [selectedMember1, setSelectedMember1] = useState<string | undefined>();
    const [selectedMember2, setSelectedMember2] = useState<string | undefined>();

    useEffect(() => {
        if (members.length === 2) {
            setSelectedMember1(members[0].username);
            setSelectedMember2(members[1].username);
        }
    }, [members]);

    const { data: similarityScores, isLoading } = useQuery(
        ['similarityScores', selectedMember1, selectedMember2],
        () => fetchSimilarityScores(selectedMember1!, selectedMember2!),
        {
            enabled: !!selectedMember1 && !!selectedMember2,
        }
    );

    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: SimilrScore, b: SimilrScore) => a.name.localeCompare(b.name),
        },
        {
            title: 'Score (%)',
            dataIndex: 'score',
            key: 'score',
            sorter: (a: SimilrScore, b: SimilrScore) => a.score - b.score,
            render: (score: number) => Math.round(score * 100),
            defaultSortOrder: 'descend' as const,
        },
    ];

    return (
        <Card>
            <Select
                showSearch
                style={{ width: '45%', marginBottom: '1rem' }}
                placeholder="Select the first member"
                onChange={setSelectedMember1}
                value={selectedMember1}
                optionFilterProp="children"
            >
                {members.filter(member => member.username !== selectedMember2).map(member => (
                    <Select.Option key={member.username} value={member.username}>{member.fullname}</Select.Option>
                ))}
            </Select>
            <Select
                showSearch
                style={{ width: '45%', marginBottom: '1rem', marginLeft: '10px' }}
                placeholder="Select the second member"
                onChange={setSelectedMember2}
                value={selectedMember2}
                optionFilterProp="children"
            >
                {members.filter(member => member.username !== selectedMember1).map(member => (
                    <Select.Option key={member.username} value={member.username}>{member.fullname}</Select.Option>
                ))}
            </Select>
            {isLoading ? (
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    paddingTop: '50px' // Adjust padding to move down as needed
                }}>
                    <Spin size='large' />
                </div>
            ) : similarityScores ? (
                <Table
                    size='small'
                    columns={columns}
                    dataSource={[...similarityScores.categories, { name: 'Overall Similarity', score: similarityScores.overall }]}
                    pagination={false}
                    rowKey="name"
                    rowClassName={(record) => record.name === 'Overall Similarity' ? 'boldRow' : ''}
                />

            ) : (
                selectedMember1 && selectedMember2 && <p>Select two different members to view similarity scores.</p>
            )}
        </Card>
    );
};

export default SimilrSection;
