import React from 'react';
import { Card, Typography } from 'antd';
const { Paragraph } = Typography;

const TermsConditions = () => {

  const titleStyle: React.CSSProperties = {
    fontSize: '24px', // Bigger font size for the title
    textAlign: 'center', // Center the title text
    fontWeight: 'bold', // Make the title text bold
    padding: '20px',
  };

  return (
    <>
        <Card title={<div style={titleStyle}>Terms and Conditions for Similr.ai</div>} bordered={false} style={{ width: '100%', maxWidth: '700px', margin: 'auto', textAlign: 'center' }}>
            <>
                <Paragraph style={{ textAlign: 'left', fontSize: '14px'  }} >
1. Introduction
<br />
Welcome to Similr.ai. These Terms and Conditions outline the rules and regulations for the use of our website and services. By accessing Similr.ai, you consent to accept these Terms and Conditions.
<br />
<br />
2. User Accounts and Activities
<br />
Account Creation: Users are required to create an account to access certain features of the website, including answering questions and joining groups.
User Responsibilities: Users are responsible for maintaining the confidentiality of their account information and for all activities under their account.
Acceptable Use: Users agree to use the website for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of the site by any third party.

<br />
<br />
3. Services Offered
<br />
Similr.ai provides a platform for users to answer questions, receive results, create and join groups. These services are subject to change or termination without notice in the sole discretion of Similr.ai.

<br />
<br />
4. Intellectual Property Rights
<br />
Other than the content you own, under these Terms, Similr.ai and/or its licensors own all the intellectual property rights and materials contained in this website.

<br />
<br />
5. Limitations of Use
<br />
You are specifically restricted from all of the following:
<br />
Any unauthorized access or use of the website and its services.
Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this website.
Using this website in any way that is or may be damaging to this website.

<br />
<br />
6. No Warranties
<br />
This website is provided "as is," with all faults, and Similr.ai expresses no representations or warranties, of any kind related to this website or the materials contained on this website.

<br />
<br />
7. Limitation of Liability
<br />
In no event shall Similr.ai, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website.
<br />
<br />

8. Sharing of Results on Social Media
<br />
User-Initiated Sharing: Users may choose to share their results from Similr.ai on social media platforms, including but not limited to Twitter and Instagram.
Guidelines for Sharing: When sharing results, users should:
Ensure they do not share sensitive personal information.
Respect the privacy of others; do not share another person's results without their explicit consent.
Acknowledge that shared content is publicly visible and users are responsible for such sharing.
Use of Our Brand: Any use of Similr.ai's brand, logo, or other proprietary graphics in shared content must be in accordance with our brand guidelines and should not imply endorsement or partnership, unless expressly permitted by Similr.ai.
<br />
<br />

9. Third-Party Websites and Content
<br />
The Website may contain (or you may be sent through the Website) links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content"). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Website or any Third-Party Content posted on, available through, or installed from the Website.
<br />
<br />

10. Indemnification
<br />
You hereby indemnify to the fullest extent Similr.ai from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.
<br />
<br />

11. Severability
<br />
If any provision of these Terms is found to be invalid under
any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
<br />
<br />

12. Variation of Terms
<br />
Similr.ai is permitted to revise these Terms at any time as it sees fit, and by using this Website, you are expected to review these Terms on a regular basis.
<br />
<br />

13. Assignment
<br />
Similr.ai is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
<br />
<br />

14. Nature of Service
<br />
For Entertainment and Informational Purposes: <br />
The personality assessments, quizzes, and tests provided on Similr.ai are for entertainment and informational purposes only. They are not designed to provide professional or certified psychological evaluations.
<br />

No Professional Advice: <br />
The results and insights provided by Similr.ai should not be construed as psychological counseling, therapy, or professional advice of any kind. Users are advised to seek professional assistance for such services.
<br />

No Accuracy Guarantee: <br />
While we strive to provide insightful and engaging content, Similr.ai does not guarantee the accuracy, relevance, or applicability of the results. The assessments are based on general analyses and may not accurately reflect the complexities of an individual’s personality or life circumstances.
<br />

Limitation of Liability: <br />
As stated in Section 7, Similr.ai, its officers, directors, and employees shall not be liable for any interpretation or use of the assessment results beyond their intended purpose as outlined in these Terms.
<br />
<br />

15. User Responsibility
<br />
Interpretation and Use: <br />
Users are responsible for their interpretation and use of any results or content provided by Similr.ai. Users should use their judgment and discretion in interpreting the applicability of any insights or recommendations.
<br />

Sharing Results: <br />
If users choose to share their results publicly, including on social media platforms, they should consider the personal nature of these results and respect the privacy of others.
<br />
<br />

16. Experimental Nature of AI Technology
<br />
Evolving Technology: <br />
Users acknowledge that the artificial intelligence (AI) technology used on Similr.ai is an evolving and experimental field. The algorithms, methodologies, and data employed in our services are subject to change and continuous improvement.
<br />

Limitations of AI: <br />
While we endeavor to provide insightful and accurate results, the AI-driven assessments and recommendations should be understood as approximations and interpretations based on available data. These outputs may not fully encompass the complexities and nuances of human personality, behavior, or preferences.
<br />

No Substitute for Human Judgment: <br />
The AI-generated results and content provided by Similr.ai should not be used as a substitute for human judgment, decision-making, or professional advice. Users are encouraged to use their discretion and seek professional opinions where appropriate.
<br />

Feedback and Improvement: <br />
We welcome user feedback to improve our AI algorithms and services. However, users understand that their feedback does not necessarily guarantee changes or improvements to the AI system.
<br />
<br />

17. User Responsibility
<br />
Interpretation and Use: <br />
Users are responsible for their interpretation and application of any AI-generated results or content. Similr.ai is not responsible for decisions or actions taken based on these results.
<br />

Consent to Use AI: <br />
By using Similr.ai, users consent to the processing of their data by our AI systems within the scope of our Privacy Policy and these Terms and Conditions.
<br />
<br />

18. Changes to Services
<br />
Right to Modify or Discontinue: <br />
Similr.ai reserves the right to modify, suspend, or discontinue, temporarily or permanently, any part of its services, including the generation of reports, without notice at any time.
<br />

No Liability: <br />
Similr.ai shall not be liable to you or any third party for any modification, suspension, or discontinuance of the services. This includes any direct, indirect, incidental, special, consequential, or exemplary damages.
<br />

Notice of Changes: <br />
While not obligated, Similr.ai may provide notice of significant changes to its services where feasible. Users are encouraged to frequently review the Terms and Conditions for any modifications.
<br />
<br />

19. User Acknowledgment
<br />
Acceptance of Risk: <br />
By using Similr.ai, users acknowledge and accept the risk that services, including report generation, may change or cease to be available at any time.
<br />

Preparation for Discontinuation: <br />
Users are advised to retain copies or records of their important data or reports, as Similr.ai may not provide access to or copies of data following the discontinuation or modification of a service.
<br />
<br />

20. Simulated Responses of Famous Characters
<br />
Nature of Simulated Responses: <br />
Similr.ai features simulated responses of both real and fictional famous characters. Users understand that these are created for entertainment purposes and do not represent real or endorsed responses of the individuals or entities.
<br />

No Affiliation or Endorsement: <br />
The inclusion of a famous character's on Similr.ai does not imply any affiliation with or endorsement by the individual or entity that the character represents. All simulated responses are generated by our algorithms and should be viewed as part of the entertainment aspect of our services.
<br />

Fictional Representation: <br />
For fictional characters, users acknowledge that these are purely imaginary and are included for entertainment and illustrative purposes only.
<br />
<br />

21. Intellectual Property and Rights
<br />
Respect for Rights: <br />
Similr.ai respects the intellectual property rights of others and endeavors to ensure that the content used in the simulation of famous characters does not infringe upon these rights.
<br />

User Acknowledgment: <br />
By using the service, users acknowledge that any similarity to real individuals, living or dead, or to other fictional characters is purely coincidental and not intended to mimic or represent actual personalities or affiliations.
<br />
<br />

22. Entire Agreement
<br />
These Terms constitute the entire agreement between Similr.ai and you in relation to your use of this Website, superseding all prior agreements and understandings.
<br />
<br />

23. Governing Law & Jurisdiction
<br />
These Terms will be governed by and interpreted in accordance with the laws of the jurisdiction in which Similr.ai is located, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Similr.ai's jurisdiction for the resolution of any disputes.
                </Paragraph>
            </>
        </Card>
    </>
  );
};

export default TermsConditions;
