import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Spin, Select, Tooltip, List, Col, Row } from 'antd';

const { Option } = Select;

interface Recommendation {
    rec_title: string;
    rec_description: string;
    similarity_score: number;
}

interface UserTop10 {
    user_name: string;
    top10_recommendations: Recommendation[];
}

const fetchCategories = async (): Promise<string[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/categories/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });
  
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
  };  

const fetchTop10 = async (groupName: string, category: string): Promise<UserTop10[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/groups/top10/?tag=${encodeURIComponent(groupName)}&category=${encodeURIComponent(category)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok while fetching top 10');
    }
    return response.json();
};

interface Top10SectionProps {
    groupName: string;
}


const Top10Section: React.FC<Top10SectionProps> = ({ groupName }) => {
    const [selectedCategory, setSelectedCategory] = useState('General Personality');
    const { data: top10Data, isLoading } = useQuery(['top10', groupName, selectedCategory], () => fetchTop10(groupName, selectedCategory));
    const { data: categories } = useQuery('categories', fetchCategories);

    const handleCategoryChange = (value: string) => {
        setSelectedCategory(value);
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '100px' }}>
                <Spin size="large" />
            </div>
        );
    }

    // Sort the data by username
    const sortedTop10Data = [...(top10Data || [])].sort((a, b) => a.user_name.localeCompare(b.user_name));

    // Determine column span based on number of people
    const colSpan = sortedTop10Data.length > 0 ? 24 / sortedTop10Data.length : 24;

    return (
        <div>
            <Select
                style={{ width: 200, marginBottom: 20 }}
                onChange={handleCategoryChange}
                placeholder="Select Category"
                value={selectedCategory}
            >
                {categories?.sort((a, b) => a.localeCompare(b)).map(category => (
                    <Option key={category} value={category}>{category}</Option>
                ))}
            </Select>
            <Row gutter={[16, 16]}>
                {sortedTop10Data.map(user => (
                    <Col xs={24} sm={12} lg={Math.max(6, colSpan)} key={user.user_name}>
                        <h4>{user.user_name}</h4>
                        <List
                            dataSource={user.top10_recommendations}
                            renderItem={(rec, index) => (
                                <List.Item>
                                    <Tooltip title={rec.rec_description}>
                                        <span>{index + 1}. {rec.rec_title} ({Math.round(rec.similarity_score * 1000)})</span>
                                    </Tooltip>
                                </List.Item>
                            )}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Top10Section;