import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import LoginForm from './components/LoginForm';
import AuthProvider from './AuthProvider';
import { AuthContext } from './AuthContext';
import Landing from './components/Landing';
import SignupForm from './components/SignupForm';
import RapidFire from './components/RapidFire/RapidFire';
import Similr from './components/Similr/Similr';
import Recommendation from './components/Recommendations/Recommendations';
import PredictChoice from './components/PredictChoice/PredictChoice';
import TopWords from './components/TopWords/TopWords';
import Compare from './components/Compare/Compare';
import SimilrChartPage from './components/SimilrChart/SimilrChartPage';
import SlideshowPage from './components/Slideshow/SlideshowPage';
import HowItWorks from './components/HowItWorks';
import TermsConditions from './components/TermsConditions';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import PricingPage from './components/PricingPage';
import CustomGenPage from './components/CustomGen/CustomGenPage';
import GroupsPage from './components/Groups/GroupsPage';
import AccountPage from './components/Account/AccountPage';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </QueryClientProvider>
  );
};

const AppRoutes: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Router>
       <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%' }}> {/* Ensure full height */}
          <HeaderComponent />
          <main style={{ flex: '1', padding: '30px', maxWidth: '1200px', margin: '0 auto' }}>
            <Routes>
              <Route path="/" element={isLoggedIn ? <Navigate to="/rapidfire" /> : <Landing />} />
              <Route path="/account" element={isLoggedIn ? <AccountPage /> : <Navigate to="/login" />} />
              <Route path="/rapidfire" element={isLoggedIn ? <RapidFire /> : <Navigate to="/login" />} />
              <Route path="/results/:slideNumber" element={isLoggedIn ? <SlideshowPage /> : <Navigate to="/login" />} />
              <Route path="/results" element={isLoggedIn ? <SlideshowPage /> : <Navigate to="/login" />} />
              <Route path="/groups" element={isLoggedIn ? <GroupsPage /> : <Navigate to="/login" />} />
              <Route path="/custom-gen" element={isLoggedIn ? <CustomGenPage /> : <Navigate to="/login" />} />
              <Route path="/compare" element={isLoggedIn ? <Compare /> : <Navigate to="/login" />} />
              <Route path="/similr" element={isLoggedIn ? <Similr /> : <Navigate to="/login" />} />
              <Route path="/similr-chart" element={isLoggedIn ? <SimilrChartPage /> : <Navigate to="/login" />} />
              <Route path="/recommendations" element={isLoggedIn ? <Recommendation /> : <Navigate to="/login" />} />
              <Route path="/predict-choice" element={isLoggedIn ? <PredictChoice /> : <Navigate to="/login" />} />
              <Route path="/topwords" element={isLoggedIn ? <TopWords /> : <Navigate to="/login" />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/signup" element={<SignupForm />} />
              <Route path="/howitworks" element={<HowItWorks />} />
              <Route path="/termsconditions" element={<TermsConditions />} />
              <Route path="/pricing" element={<PricingPage />} />
            </Routes>
          </main>
          <FooterComponent />
      </div>
    </Router>
  );
};

export default App;
