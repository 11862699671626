import React, { useContext, useEffect, useState } from 'react';
import Slide from './Slide';
import Confetti from 'react-confetti';
import { Alert, Button, Card, Col, Popover, Row, Select } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import { QuestionCircleOutlined } from '@ant-design/icons';


const reportNameMapping: { [key: string]: string } = {
  personality: 'Personality Summary',
  career: 'Career Advice',
  matchmaker: 'Matchmaker',
  dating_profile: 'Dating Profile',
  dnd: 'D&D Character',
  animal: 'Your Animal',
};

interface ReportOptions {
  reportType: 'dontRun' | 'gpt3' | 'gpt4';
  imageType?: 'reused' | 'unique';
}

const initialReportOptions: Record<string, ReportOptions> = {
  personality: { reportType: 'gpt3', imageType: 'reused' },
  career: { reportType: 'gpt3', imageType: 'reused' },
  matchmaker: { reportType: 'gpt3', imageType: 'reused' },
  dating_profile: { reportType: 'gpt3' },
  dnd: { reportType: 'gpt3', imageType: 'reused' },
  animal: { reportType: 'gpt3', imageType: 'reused' },
}

interface WelcomeSlideProps {
  username: string;
}

const WelcomeSlide: React.FC<WelcomeSlideProps> = ({ username }) => {
  const { updateCreditBalance } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [confettiPieces, setConfettiPieces] = useState(0);

  const [userCredits, setUserCredits] = useState(0); // Assume you will fetch this from backend or context
  const [totalCredits, setTotalCredits] = useState(0); // Define the totalCredits state

  const [reportOptions, setReportOptions] = useState(initialReportOptions);
  
  const [toggleReportIndex, setToggleReportIndex] = useState(0);
  const [toggleImageIndex, setToggleImageIndex] = useState(0);

  const reportTypes = ['dontRun', 'gpt3', 'gpt4'];
  const imageTypes = ['reused', 'unique'];

  const toggleAllReports = () => {
    const nextIndex = (toggleReportIndex + 1) % reportTypes.length;
    setToggleReportIndex(nextIndex);
    const nextType = reportTypes[nextIndex] as 'dontRun' | 'gpt3' | 'gpt4';
    setReportOptions(prev => Object.fromEntries(
      Object.entries(prev).map(([key, val]) => [key, {...val, reportType: nextType}])
    ));
  };
  
  const toggleAllImages = () => {
    if (!initialReportOptions.dating_profile.imageType) { // Skip if no image option for dating_profile
      const nextIndex = (toggleImageIndex + 1) % imageTypes.length;
      setToggleImageIndex(nextIndex);
      const nextType = imageTypes[nextIndex];
      setReportOptions(prev => Object.fromEntries(
        Object.entries(prev).map(([key, val]) => val.imageType ? [key, {...val, imageType: nextType}] : [key, val])
      ));
    }
  };

  const areAllReportsSetToDontRun = () => {
    return Object.values(reportOptions).every(option => option.reportType === 'dontRun');
  };
  
  const handleReportOptionChange = (reportKey: string, optionKey: keyof ReportOptions, value: string) => {
    setReportOptions((prev) => ({
      ...prev,
      [reportKey]: { ...prev[reportKey], [optionKey]: value },
    }));
  };

  useEffect(() => {
    const creditCosts = {
      dontRun: 0, // Add this line to handle 'dontRun' case
      gpt3: 1,
      gpt4: 10,
      unique: 20,
    };
    let total = 0;
    Object.values(reportOptions).forEach((option) => {
      total += creditCosts[option.reportType];
      if (option.imageType) {
        total += (option.imageType === 'unique' ? creditCosts[option.imageType] : 0);
      }
    });
    setTotalCredits(total); // Correctly set the totalCredits state
  }, [reportOptions]);

  const authToken = localStorage.getItem('authToken');  // Fetch auth token from local storage or context
  const backendUrl = process.env.REACT_APP_BACKEND_URL; // Fetch the backend URL from environment variables

  // Updated to fetch user's credits and ability to generate new report
  const fetchUserDetails = async () => {
    const response = await fetch(`${backendUrl}api/can-create-reports/`, {
      method: 'GET',
      headers: {
        'Authorization': `Token ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  const { data: overviewStatus, isError: isUserDetailsError } = useQuery('userDetails', fetchUserDetails, {
    onSuccess: async (data) => {
      setUserCredits(data.user_credits);
      await updateCreditBalance();
    },
    refetchOnWindowFocus: false,
  });

  // Trigger report generation
  const triggerReportGeneration = async () => {
    const response = await fetch(`${backendUrl}api/trigger-reports-generation/`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reportOptions, // send the selected report options
      }),
    });
    if (!response.ok) {
      throw new Error('Failed to trigger report generation');
    }
    //const result = await response.json();

    // Trigger confetti
    setConfettiPieces(200);
    setTimeout(() => setConfettiPieces(0), 5000); // Stop confetti after 5 seconds

    // Refetch user details to get updated overview status
    queryClient.refetchQueries('userDetails');

  };

  // Handle error state
  if (isUserDetailsError) {
    console.error("Error fetching overview status: ", isUserDetailsError);
  }

  const titleStyle: React.CSSProperties = {
    fontSize: '24px', // Bigger font size for the title
    textAlign: 'center', // Center the title text
    fontWeight: 'bold', // Make the title text bold
    padding: '10px',
  };

  const tooSoonForNewResults = (message: string): boolean => {
    const has_enough_answers: boolean = (overviewStatus.num_questions_for_new_results - (overviewStatus.num_user_answers - overviewStatus.num_answers_last_overview) <= 0)
      return has_enough_answers && (message === 'Not enough time between results.')
  }

  const popoverContent = (
    <div style={{ maxWidth: '800px'}}>
        <p><strong>GPT-3.5 vs GPT-4:</strong></p>
        <ul>
            <li><strong>GPT-3.5 (1 credit):</strong> Is more cost-effective and can usually produce decent summaries.</li>
            <li><strong>GPT-4 (10 credits):</strong> Produces state-of-the-art responses that are more nuanced and sophisticated than GPT-3.5.</li>
        </ul>
        <p><strong>Unique Image (20 credits):</strong></p>
        <ul>
            <li>This option signifies that a one-of-a-kind image will be generated based on your report. The image is crafted using AI to reflect the unique aspects of your personality and report content.</li>
            <li>Each "Unique Image" is exclusive to you and won't be replicated for anyone else's report, ensuring a personal touch that is distinctively yours.</li>
            <li>Choosing "Unique Image" enhances your report with a visual representation that is as unique as the insights derived from your answers.</li>
            <li>You retain the rights to your unique images.  We only reserve the right to continue to serve them to you in Similr.ai.</li>
        </ul>
    </div>
  );

  return (
    <Slide>
      <Confetti numberOfPieces={confettiPieces}></Confetti>
      <Card title={<div style={titleStyle}>{overviewStatus && overviewStatus.num_user_answers >= overviewStatus.num_questions_for_new_results ? `Congratulations, ${username}! (${overviewStatus.num_user_answers})` : (overviewStatus ? "Not Enough Questions Answered" : "")}</div>} bordered={false} style={{ width: '100%', maxWidth: '700px', margin: 'auto', textAlign: 'center' }}>
              
        {overviewStatus && overviewStatus.can_generate_new_report && (
          <>
            {overviewStatus.num_user_answers < 250 && (
              <Alert 
                message={(
                  <div>
                    You can run new reports every 100+ questions you answer.
                    <br />
                    We recommend selecting GPT-4 and Unique Images after 250+ questions as the results are better.
                  </div>
                )}
                type="warning" 
                style={{ marginBottom: '10px' }}
              />
            )}
            <Alert 
              message={(
                <div>
                  Choose generation info for result reports - <strong>Slides 15-20</strong>.
                  <br />
                  GPT-4 produces higher quality reports than GPT-3.5.
                  <br />
                  Choose Unique Image if you want your own unique image generated based on your report.
                  <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                    <Popover content={popoverContent}>
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                </div>
              )}
              type="info" 
              style={{ marginBottom: '10px' }}
            />

            {Object.entries(reportOptions).map(([key, options]) => (
              <Row key={key} gutter={16} align="middle" style={{ marginBottom: '0' }}>
                <Col span={8}>
                  <p style={{ fontSize: '15px', fontWeight: 500, textAlign: 'left' }} >{reportNameMapping[key]}</p>
                </Col>
                <Col span={8}>
                  <Select
                    value={options.reportType}
                    onChange={(value) => handleReportOptionChange(key, 'reportType', value)}
                    style={{ width: '100%' }}
                  >
                    <Select.Option value="dontRun">Don't Run</Select.Option>
                    <Select.Option value="gpt3">GPT-3.5 (1 credit)</Select.Option>
                    <Select.Option value="gpt4">GPT-4 (10 credits)</Select.Option>
                  </Select>
                </Col>
                {options.imageType && (
                  <Col span={8}>
                    <Select
                      value={options.imageType}
                      onChange={(value) => handleReportOptionChange(key, 'imageType', value)}
                      style={{ width: '100%' }}
                    >
                      <Select.Option value="reused">Reused Image</Select.Option>
                      <Select.Option value="unique">Unique Image (20 credits)</Select.Option>
                    </Select>
                  </Col>
                )}
              </Row>
            ))}
      
            <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
              <Button onClick={toggleAllReports} type="default">Toggle All Reports</Button>
              <Button onClick={toggleAllImages} type="default" style={{ marginLeft: '10px' }}>Toggle All Images</Button>
            </div>
      
            <div style={{ fontSize: '15px', textAlign: 'center', margin: '20px 0' }}>
              <div>Credits Required: <strong>{totalCredits}</strong> | Credits Available: <strong>{userCredits}</strong></div>
              <Button
                style={{ marginTop: '10px' }}
                size='large'
                type='primary'
                onClick={triggerReportGeneration} disabled={totalCredits > userCredits || areAllReportsSetToDontRun()}
              >
                Run Reports!
              </Button>
            </div>
          </>
        )}
        {overviewStatus && !overviewStatus.is_generating && !overviewStatus.new_overview_triggered && (overviewStatus.num_user_answers < overviewStatus.num_questions_for_new_results) && (
          <Alert 
            message="You have no results"
            description={(
              <div>
                To generate new results you need to answer 
                <strong> {overviewStatus.num_questions_for_new_results - (overviewStatus.num_user_answers - overviewStatus.num_answers_last_overview)} </strong>
                more questions.
                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                  <Link to="/rapidfire">
                      <Button size="large" type="primary">
                          Go to Questions
                      </Button>
                  </Link>
                 </div>
              </div>
            )}
            type="error" 
            showIcon
            style={{ marginBottom: '20px' }}
          />
        )}

        {overviewStatus && tooSoonForNewResults(overviewStatus.message) && (
          <Alert
            message="Too soon for new results." 
            description={(
              <div>
                Please answer some more questions and try again later.
                <br></br>
                To generate new results you need to answer 
                <strong> {overviewStatus.num_questions_for_new_results - (overviewStatus.num_user_answers - overviewStatus.num_answers_last_overview)} </strong>
                more questions.
                <br></br>
                Results last generated at <strong>{overviewStatus.num_answers_last_overview}</strong>.
                <br></br>
                Current number of answers <strong>{overviewStatus.num_user_answers}</strong>.
              </div>
            )}
            type="info" 
            showIcon
            style={{ marginBottom: '20px' }}
          />
        )}
        {overviewStatus && !overviewStatus.can_generate_new_report && !tooSoonForNewResults(overviewStatus.message) && !overviewStatus.is_generating && !overviewStatus.new_overview_triggered && (overviewStatus.num_user_answers >= overviewStatus.num_questions_for_new_results) && (
          <Alert 
            message="Your results are completed" 
            description={(
              <div>
                To generate new results you need to answer 
                <strong> {overviewStatus.num_questions_for_new_results - (overviewStatus.num_user_answers - overviewStatus.num_answers_last_overview)} </strong>
                more questions.
                <br></br>
                Results last generated at <strong>{overviewStatus.num_answers_last_overview}</strong>.
                <br></br>
                Current number of answers <strong>{overviewStatus.num_user_answers}</strong>.
              </div>
            )}
            type="info" 
            showIcon
            style={{ marginBottom: '20px' }}
          />
        )}
      </Card>
    </Slide>
  );
};

export default WelcomeSlide;
