import React from 'react';
import { Table } from 'antd';

interface DataItem {
    key: string;
    trait: string;
    adults: number;
    child: number;
    males: number;
    females: number;
    fictionalCharacter: number;
    realPerson: number;
    [key: string]: number | string; // Allows dynamic indexing
}
  
const TraitsTable = () => {
  const originalData = [
    { key: '1', trait: 'Realistic', adults: 82, child: 41, males: 91, females: 43, fictionalCharacter: 75, realPerson: 73 },
    { key: '2', trait: 'Analytical', adults: 67, child: 9, males: 71, females: 26, fictionalCharacter: 39, realPerson: 69 },
    { key: '3', trait: 'Creative', adults: 58, child: 48, males: 62, females: 50, fictionalCharacter: 73, realPerson: 46 },
    { key: '4', trait: 'Philosophical', adults: 52, child: 50, males: 49, females: 58, fictionalCharacter: 30, realPerson: 73 },
    { key: '5', trait: 'Assertive', adults: 3, child: -25, males: -1, females: -5, fictionalCharacter: -1, realPerson: -6 },
    { key: '6', trait: 'Empathetic', adults: -11, child: 50, males: -39, females: 76, fictionalCharacter: -3, realPerson: 3 },
    { key: '7', trait: 'Introverted', adults: -15, child: 58, males: -13, females: 27, fictionalCharacter: 8, realPerson: -4 },
    { key: '8', trait: 'Optimistic', adults: -16, child: -3, males: -20, females: 2, fictionalCharacter: -10, realPerson: -17 },
    { key: '9', trait: 'Adventurous', adults: -37, child: 27, males: -27, females: -14, fictionalCharacter: 37, realPerson: -72 },
    { key: '10', trait: 'Extroverted', adults: -41, child: 6, males: -50, females: 6, fictionalCharacter: -8, realPerson: -51 }
  ];
  const transformedData = originalData[0] ? Object.keys(originalData[0]).slice(1).map(key => ({
    key: key,
    trait: key,
    ...originalData.reduce((obj, item) => {
      const dataItem = item as DataItem; // Type assertion
      return { ...obj, [dataItem.trait]: dataItem[key as keyof DataItem] };
    }, {} as { [key: string]: number | string })
  })).filter(item => item.key !== 'trait') : []; // Exclude the 'trait' row
  
  // Define columns based on the traits
  const columns = [
    { 
      title: 'Group', 
      dataIndex: 'trait', 
      key: 'trait'
    },
    ...originalData.map(item => ({
      title: item.trait,
      dataIndex: item.trait,
      key: item.trait,
      sorter: (a: any, b: any) => a[item.trait] - b[item.trait]
    }))
  ];
  
  return <Table size='small' pagination={false} dataSource={transformedData} columns={columns} />;
};

export default TraitsTable;
