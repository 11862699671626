// AuthContext.tsx
import React from 'react';

interface AuthContextInterface {
    isLoggedIn: boolean;
    name: string | null;
    username: string | null;
    credit_balance: number;
    updateCreditBalance: () => Promise<void>;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
    signup: (username: string, password: string, name: string) => Promise<void>;
}

// Create a context with default values
export const AuthContext = React.createContext<AuthContextInterface>({
    isLoggedIn: false,
    name: '',
    username: '',
    credit_balance: 0,
    updateCreditBalance: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => { },
    signup: () => Promise.resolve(),
});
