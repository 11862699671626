import React, { useState } from 'react';
import { Tabs, Spin, Alert } from 'antd';
import { useQuery } from 'react-query';
import CreateCustomGen from './CreateCustomGen';
import CustomGenList from './CustomGenList';
import AboutAIGeneratedContent from './AboutAIGeneratedContent';

const { TabPane } = Tabs;

const fetchQuestionCount = async (): Promise<number> => {
    // Replace with your actual API call to get the question count
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/user-question-count/`, {
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.num_questions;
};

const CustomGenPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState('newContent');
    const { data: questionCount, isLoading } = useQuery('questionCount', fetchQuestionCount);

    const onTabChange = (key: string) => {
        setActiveTab(key);
    };

    if (isLoading) {
        return <Spin size="large" />;
    }

    return (
        <div>
            {questionCount && questionCount >= 100 ? (
                <Tabs activeKey={activeTab} onChange={onTabChange} style={{ width: '1000px' }}>
                    <TabPane tab="Create New Content" key="newContent">
                        <CreateCustomGen />
                    </TabPane>
                    <TabPane tab="Your Content" key="previousContent">
                        <CustomGenList />
                    </TabPane>
                    <TabPane tab="About" key="aboutAIContent">
                        <AboutAIGeneratedContent />
                    </TabPane>
                </Tabs>
            ) : (
                <Alert
                    message="Locked"
                    description="Unlocks after you've answered 100 questions."
                    type="info"
                    showIcon
                    style={{ width: '900px', textAlign: 'center', margin: '20px auto' }}
                />
            )}
        </div>
    );
};

export default CustomGenPage;
