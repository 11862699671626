import React, { useContext } from 'react';
import { Typography, Button, Row, Col, Image, Divider, Carousel, Alert } from 'antd';
import { AuthContext } from '../AuthContext';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const Landing: React.FC = () => {
    const { isLoggedIn, username } = useContext(AuthContext);

    const renderSectionButton = (text: string, showHow: boolean = false) => (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button type="primary" size="large" style={{ marginRight: '20px' }} >
                <Link to="/signup">{text}</Link>
            </Button>
            {showHow && (
                <Button type="default" size="large">
                  <Link to="/howitworks">How does this work?</Link>
                </Button>
            )}
        </div>
    );

    return (
        <>
            <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
                <Title level={1} style={{ textAlign: 'center' }}>Welcome to Similr.ai</Title>
                <Paragraph style={{ textAlign: 'center', fontSize: '18px' }}>
                    {isLoggedIn ? (
                        `Welcome back, ${username}! Discover new insights and connect with friends.`
                    ) : (
                        `What famous person real or fictional are you most like?*  Which of your friends are you most like?`
                    )}
                </Paragraph>
                {renderSectionButton('Find out now', true)}
                <Divider />
                <Row gutter={[16, 32]}>
                    <Col lg={12} sm={24}>
                        <Carousel autoplay autoplaySpeed={4000} dots={false}>
                            <div>
                                <Image
                                    src="question1.png"
                                    alt="Image Description 1"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="question2.png"
                                    alt="Image Description 2"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="question3.png"
                                    alt="Image Description 3"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="question4.png"
                                    alt="Image Description 4"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="question5.png"
                                    alt="Image Description 5"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="question6.png"
                                    alt="Image Description 6"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Carousel>
                    </Col>
                    <Col lg={12} sm={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '25px'}}>
                        <Title level={2}>Simply answer this or that questions</Title>
                        <Paragraph style={{ fontSize: '15px' }}>
                        Answer questions where you choose one or the other or skip.
                        Similr.ai uses advanced AI (OpenAI) to create a summary of all your choices
                        which is then used to predict your personality, recommended careers, likes, and dislikes.
                        </Paragraph>
                        {renderSectionButton('Let\'s go!')}
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 32]}>
                    <Col lg={12} sm={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingRight: '25px' }}>
                        <Title level={2}>Invite friends and family with groups</Title>
                        <Paragraph style={{ fontSize: '15px' }} >
                        Create private groups to see how you and your friends or family align and differ in personalities.
                        Understand your group's dynamics, discover similarities and differences, and find out if someone is similar to a Capybara!
                        </Paragraph>
                        {renderSectionButton('Let\'s get started')}
                    </Col>
                    <Col  lg={12} sm={24}>
                        <Image
                            src="friendsanimals.png"
                            alt="Friends and Family Groups"
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 32]}>
                    <Col lg={12} sm={24}>
                    <Carousel autoplay autoplaySpeed={4000} dots={false}>
                            <div>
                                <Image
                                    src="animalreport.png"
                                    alt="Image Description 1"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="dndreport.png"
                                    alt="Image Description 2"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="personalityreport.png"
                                    alt="Image Description 3"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="careereport.png"
                                    alt="Image Description 4"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <Image
                                    src="datingprofile.png"
                                    alt="Image Description 6"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Carousel>
                    </Col>
                    <Col lg={12} sm={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '25px' }}>
                        <Title level={2}>AI generated reports just for you</Title>
                        <Paragraph style={{ fontSize: '15px' }} >
                        We generate <strong>6 personalized reports</strong> for you: personality, career, relationship advice,
                        a mock dating profile, a D&D character based on you, and what animal you would be if you were an animal.
                        </Paragraph>
                        {renderSectionButton('I want personalized reports')}
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 32]}>
                    <Col lg={12} sm={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingRight: '25px' }}>
                        <Title level={2}>Who are you Similr to?</Title>
                        <Paragraph style={{ fontSize: '15px' }} >
                        Once you've completed enough questions you can see how you relate to over 160 famous real and fictional
                        people.  We give you two main ways to do this: A direct number from -100 to 100 with -100 being exact
                        opposite and 100 means you are exactly the same and a 2D chart showing how everyone including you groups up.
                        </Paragraph>
                        {renderSectionButton('I want to find out')}
                    </Col>
                    <Col lg={12} sm={24}>
                        <Image
                            src="similrchart.png"
                            alt="Personalized Recommendations"
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
                <Divider />
                <Alert
                    style={{ maxWidth: '800px', margin: 'auto' }}
                    type='info'
                    message='* Simulated Responses of Famous Characters'
                    description={(
                        <div>
                            Similr.ai features simulated responses of both real and fictional famous characters.
                            These are created for entertainment purposes and do not represent real or endorsed responses
                            of the individuals or entities.
                            The inclusion of a famous character on Similr.ai does not imply any affiliation with or
                            endorsement by the individual or entity that the character represents. All simulated responses
                            are generated by <a href="https://openai.com/gpt-4"  rel="noreferrer" target='_blank'>OpenAI's GPT4</a> and should be viewed as part of the entertainment aspect of our
                            services.
                            <br />
                        </div>
                    )}
                />
            </div>
        </>
    );
};

export default Landing;
