import React, { useState, useContext } from 'react';
import { Button, Form, Input, Card, Alert } from 'antd';
import { AuthContext } from '../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';

const SignupForm: React.FC = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Assuming the signup method now also accepts an email and name
        authContext.signup(email, password, name).then(() => navigate('/rapidfire'));
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '90vh', paddingTop: '5vh' }}>
            <Card style={{ width: 425 }}>
                <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
                    Create Your Similr.ai Account
                </h2>
                <Form onSubmitCapture={handleSubmit} layout="vertical">
                    <Alert
                        showIcon
                        style={{ marginBottom: '20px' }}
                        type="info"
                        message="Privacy First"
                        description="Your username/email will never be shown to anyone outside your private groups. Your name will be used in your private groups instead of your email address. If you have no private groups, nobody will ever see any of these details."
                    />
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your Email!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Please input your Name!' }]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Sign Up
                        </Button>
                    </Form.Item>
                    <p>
                        You must be 13+ to use this site. By creating an account you agree to
                        the <Link to="/termsconditions">terms and conditions</Link>.
                    </p>
                </Form>
            </Card>
        </div>
    );
};

export default SignupForm;
