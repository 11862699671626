import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Select, Input, Button, message, Card, Row, Col, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

// New interface for User object
interface User {
    username: string;
    fullname: string;
    num_choices: number;
}

interface PromptTemplateTag {
  id: number;
  name: string;
}

interface OptionType {
  title: string;
  description: string;
}

// New interface for PairedOptionType
interface PairedOptionType extends OptionType {
  score: number;
}

// Fetch a list of usernames from the backend
const fetchUsernames = async (): Promise<User[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/usernames/`, {
      method: 'GET',
      headers: {
        'Authorization': `Token ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const users = await response.json();
    return users.sort((a: User, b: User) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()));
  };

  const fetchPromptTemplateTags = async (): Promise<PromptTemplateTag[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/prompt-template-tags/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const initialJson = JSON.stringify([
  {
      "title": "Apple",
      "description": "Sweet and sometimes slightly tart, with a crisp and juicy texture."
  },
  {
      "title": "Banana",
      "description": "Soft and creamy texture with a sweet and rich taste, becoming sweeter as it ripens."
  },
  {
      "title": "Orange",
      "description": "A balance of sweet and tangy, with a juicy and slightly pulpy texture."
  },
  {
      "title": "Grapes",
      "description": "Sweet with a hint of tartness, bursting with juice upon biting."
  },
  {
      "title": "Strawberry",
      "description": "A blend of sweetness and acidity, with a juicy and slightly firm texture."
  },
  {
      "title": "Mango",
      "description": "Exotically sweet and tart with a soft, fibrous texture."
  },
  {
      "title": "Watermelon",
      "description": "Extremely juicy with a mildly sweet and refreshing taste, containing subtle hints of graininess near the rind."
  },
  {
      "title": "Pineapple",
      "description": "Vibrantly sweet and tangy with a fibrous, juicy texture."
  },
  {
      "title": "Blueberry",
      "description": "Sweet and mildly tart, with a smooth and slightly firm texture."
  },
  {
      "title": "Cherry",
      "description": "Sweet with a touch of tartness, juicy with a firm and smooth texture."
  }
], null, 2); // null and 2 are for formatting the JSON in a readable way


const PredictChoice = () => {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<OptionType[]>(new Array(5).fill({ title: '', description: '' }));
  const [pairedOptions, setPairedOptions] = useState<PairedOptionType[]>([]);
  const [jsonInput, setJsonInput] = useState(initialJson);
  const { data: usernames } = useQuery('usernames', fetchUsernames);

  const [selectedTag, setSelectedTag] = useState<string>('All');
  const { data: promptTemplateTags } = useQuery('promptTemplateTags', fetchPromptTemplateTags);

  const loadJson = () => {
    try {
      const jsonData = JSON.parse(jsonInput);
      if (Array.isArray(jsonData)) {
        if (jsonData.every(obj => obj.title && obj.description)) {
          const newOptions = jsonData as OptionType[]; // cast it to the correct type
  
          // Update both options and pairedOptions
          setOptions(newOptions);
          const newPairedOptions = newOptions.map(option => ({ ...option, score: 0 })); // initialize scores as 0
          setPairedOptions(newPairedOptions);
        } else {
          message.error("Each JSON object must have 'title' and 'description' fields.");
        }
      } else {
        message.error("JSON must be an array of objects with 'title' and 'description' fields.");
      }
    } catch (error) {
      message.error("Invalid JSON: " + error);
    }
  };  

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const bodyContent = {
        username,
        options: options,
        tag: selectedTag,
      };
      const response = await fetch(`${backendUrl}api/predict-choice/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyContent),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();

      // Pair options with their scores and sort
      const newPairedOptions: PairedOptionType[] = options.map((option, index) => ({
        ...option,
        score: data.scores[index] || 0,
      })).sort((a, b) => b.score - a.score);

      setPairedOptions(newPairedOptions); // Update paired options with scores

    } catch (error) {
      message.error(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const updateOption = (index: number, value: string, field: keyof OptionType) => {
    const updatedOptions = options.map((option, idx) => (
      idx === index ? { ...option, [field]: value } : option
    ));
    setOptions(updatedOptions);
  };
    
  // Popover content
  const helpContent = (
    <div>
      <p>Ranks free form text to see user preferences.  You can see how changing the descriptions can change the scores.</p>
      <p>Use ChatGPT to generate JSON list of title and description keys.  Once loaded you can manually edit the descriptions.</p>
      <p>Make sure to select a user.</p>
      <p>You can also select a specific tag for the questions.  All questions are tagged with one or many of these tags.</p>
    </div>
  );

  return (
    <Row justify="center" align="middle" style={{ marginTop: '40px' }}>
      <Col span={14}>
        <Card 
          title={
            <span>
              Free Form Predict User Choice 
              <Popover 
                content={helpContent} 
                title="How to Use"
                overlayStyle={{ width: '900px' }}
              >
                <QuestionCircleOutlined 
                  style={{ cursor: 'pointer', marginLeft: '10px', position: 'relative', top: '-4px' }}
                />
              </Popover>
            </span>
          } 
          bordered={false}
        >
          <Select
              style={{ marginRight: '20px', width: '250px' }}
              placeholder="Select a user"
              onChange={(value) => setUsername(value)}
              value={username}
          >
              {usernames?.sort((a, b) => a.fullname.localeCompare(b.fullname)).map(user => (
                  <Option key={user.username} value={user.username}>{user.fullname}</Option>
              ))}
          </Select>
          <Select
              style={{ marginRight: '20px', width: '250px' }}
              placeholder="Select a tag"
              onChange={(value) => setSelectedTag(value)}
              value={selectedTag}
          >
              <Option kay="all" value="All">All</Option>
              {promptTemplateTags
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(tag => (
                    <Option key={tag.id} value={tag.name}>{tag.name}</Option>
                ))
              }
          </Select>

          <Button type="primary" onClick={handleSubmit} loading={loading}>Predict Choice</Button>

          <Row justify="center" style={{ margin: '20px 0' }}>
            <Col span={16}>
              <TextArea
                rows={4}
                placeholder="Enter JSON here"
                value={jsonInput}
                onChange={(e) => setJsonInput(e.target.value)}
              />
            </Col>
          </Row>
          <Row justify="center" style={{ margin: '10px 0' }}>
            <Col>
              <Button onClick={loadJson} style={{ marginRight: '10px' }}>Load JSON</Button>
              <Button onClick={() => setJsonInput('')}>Clear JSON</Button>
            </Col>
          </Row>
          <br /><br />
          {
            pairedOptions.map((option, index) => (
              <Row gutter={[16, 16]} align="middle" key={index}>
                <Col span={24}>
                  {/* Score now to the left of the title */}
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                    <div style={{ fontSize: '17px', fontWeight: 'bold' }}>
                      {option.score ? `${(option.score * 1000).toFixed(0)} - ` : ''}  {option.title}
                    </div>
                  </div>
                  <TextArea
                    rows={2}
                    value={option.description}
                    onChange={(e) => updateOption(index, e.target.value, 'description')}
                    style={{ marginBottom: '10px', borderColor: option.score ? 'green' : undefined }}
                  />
                </Col>
              </Row>
            ))
          }
        </Card>
      </Col>
    </Row>
  );
};

export default PredictChoice;