import React, { useEffect, useState } from 'react';
import { Select, Button, Spin, Popover } from 'antd';
import { Table } from 'antd';
import { useQuery } from 'react-query';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option, OptGroup } = Select;

interface User {
  name: any;
  fullname: string;
  username: string;
  num_choices: number;
  is_famous: boolean;
}

interface Tag {
  name: string;
  is_private: boolean;
}

const fetchUsernames = async (): Promise<User[]> => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/usernames/?include_privates=true`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${authToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json().then(users => users.sort((a: User, b: User) => a.username.toLowerCase().localeCompare(b.username.toLowerCase())));
};

const fetchTags = async (): Promise<Tag[]> => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/tags/`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${authToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchSimilarUsers = async (username: string, tags: string[]) => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const tagsQueryString = tags.map(tag => `tags=${encodeURIComponent(tag)}`).join('&');
  const url = `${backendUrl}api/similar-users/${username}/?${tagsQueryString}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${authToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const getName = (user: User) : string => {
  let name;
    if (typeof user.name === 'object' && user.name !== null) {
      name = `${user.name.first} ${user.name.last}`;
    } else if (typeof user.name === 'string') {
      name = user.name;
    } else {
      name = 'Unknown Name';
    }
    return name;
}

const columns = [
  {
    title: 'User',
    dataIndex: 'username',
    key: 'username',
    sorter: (a: any, b: any) => a.username.localeCompare(b.username),
    render: (text: string, record: User) => {
      return getName(record);
    },
  },
  {
    title: 'Similr Score',
    dataIndex: 'score',
    key: 'score',
    render: (text: number) => (text * 100).toFixed(0),
    sorter: (a: any, b: any) => a.score - b.score,
  },
];

const Similr = () => {
  const loggedInUsername = localStorage.getItem('username');
  const [selectedUsername, setSelectedUsername] = useState<string>(loggedInUsername || '');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [fetchData, setFetchData] = useState(true);
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    // Fetch tags on component mount
    fetchTagsData();
  }, []);

  const fetchTagsData = async () => {
    try {
      const fetchedTags = await fetchTags();
      setTags(fetchedTags);
    } catch (error) {
      console.error("Error fetching tags: ", error);
    }
  };
    
  const { data: usernames } = useQuery('usernames', fetchUsernames);

  const privateUsers = usernames?.filter(user => !user.is_famous) || [];
  const famousUsers = usernames?.filter(user => user.is_famous) || [];

  // UseQuery for Similar Users Data
  const similarQuery = useQuery(['similarData', selectedUsername, selectedTags], () => fetchSimilarUsers(selectedUsername, selectedTags), {
    enabled: fetchData
  });

  // Fetch data when selectedUsername changes
  useEffect(() => {
    if (selectedUsername) {
      setFetchData(true);
    }
  }, [selectedUsername]);

  // Reset fetchData to false after queries have been triggered
  useEffect(() => {
    if (fetchData) {
      setFetchData(false);
    }
  }, [fetchData]);

  // Handle 'Fetch Data' button click
  const handleFetchData = () => {
    setFetchData(true);
  };
  
  const helpContent = (
    <div>
      <p>This page gives you a general idea of how similar people are to other people.</p>
      <p><strong>Tags:</strong> Filter down the users using the tags. See which Stranger Things character you are most similar to! Or see which Stranger Things character another person is similr to.</p>
      <p><strong>Similarity Table:</strong> Overall similarity of the users in the table to the user that is selected. Range goes from -100 to 100 with -100 being exact opposites and 100 being exactly the same. Anything over 50 is quite similar.</p>
      <p><strong>Average User:</strong> Represents the average of all of the users in the table so if you have "Stranger Things" tag selected, Average User will be the average Stranger Things person.</p>
      <p><strong>Note:</strong> The questions are all fairly positive and are a self-assessment so if you are near a "bad" person it means you have similar positive personality traits to them.</p>
    </div>
  )

  // Check for loading or error states
  //if (similarQuery.isError) return <p>Error loading similarity data</p>;

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <div style={{ marginTop: '20px' }}>
          <Select
            mode="multiple"
            style={{ width: '500px' }}
            placeholder="Select tags"
            onChange={setSelectedTags}
            value={selectedTags}
          >
            {tags.sort((a, b) => a.name.localeCompare(b.name)).map(tag => (
              <Option key={tag.name} value={tag.name}>{tag.name}</Option>
            ))}
          </Select>
          <Button type='primary' onClick={handleFetchData} style={{ marginLeft: '10px' }}>
            Fetch Data
          </Button>
        </div>
        <div style={{ marginTop: '20px' }}>
        <Select
            style={{ width: 300 }}
            placeholder="Select a user"
            onChange={(value) => setSelectedUsername(value)}
            value={selectedUsername}
          >
            <OptGroup label="You and Private Group Members">
              {privateUsers.map(user => (
                <Option key={user.username} value={user.username}>
                  {user.fullname} ({user.num_choices})
                </Option>
              ))}
            </OptGroup>
            <OptGroup label="Famous People">
              {famousUsers.map(user => (
                <Option key={user.username} value={user.username}>
                  {user.fullname} ({user.num_choices})
                </Option>
              ))}
            </OptGroup>
          </Select>
          <Popover 
            content={helpContent} 
            title="How to Use" 
            overlayStyle={{ width: '50vw' }}
          >
            <QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: '10px', position: 'relative', top: '-4px' }} />
          </Popover>
        </div>
        <div style={{
          display: 'inline-block',
          width: '400px',
          border: '1px solid #ddd',
          borderRadius: '4px',
          padding: '10px',
          margin: '20px 0'
        }}>
          {similarQuery.isLoading ? (
            <Spin size="large" style={{ display: 'flex', justifyContent: 'center', paddingTop: '50px', paddingBottom: '50px' }} />
          ) : (
            <Table
              dataSource={similarQuery.data} 
              columns={columns} 
              rowKey={record => record.username}
              pagination={false} 
              size="small"
            />
          )}
        </div>
      </div>
    </>
  );  
};

export default Similr;
