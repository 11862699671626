import React from 'react';
import { Card } from 'antd';

const AboutAIGeneratedContent = () => {
    return (
        <Card bordered={false} style={{ width: '100%', margin: 'auto', overflow: 'hidden' }}>
            <h2>Exploring AI-Generated Insights</h2>
            <p>
                AI-generated content extends beyond creating text and images; it's a tool for gaining deeper insights into personality traits, career guidance, matchmaking, and more. By leveraging AI, you can explore various aspects of your and others' profiles to make more informed decisions and foster understanding.
            </p>

            <h3>Personalized Interactions Based on Reports</h3>
            <ul>
                <li><strong>Personality Insights:</strong> Use your personality report to ask AI questions about suitable career paths, social interactions, or self-improvement strategies. The AI can provide tailored advice based on your unique personality traits.</li>
                <li><strong>Career Guidance:</strong> Your career report can be a starting point for AI to suggest educational paths, professional skills to develop, or even specific job roles that might suit you.</li>
                <li><strong>Matchmaking and Relationship Advice:</strong> With your matchmaking report, AI can offer insights into relationship dynamics, compatibility issues, or even date night ideas.</li>
            </ul>

            <h3>Enhancing Group Dynamics</h3>
            <p>
                If you're part of a private group, AI can analyze multiple reports to offer collective insights. This feature is especially useful for planning activities that align with everyone's preferences or addressing group challenges.
            </p>
            <ul>
                <li><strong>Group Activity Suggestions:</strong> For a friend group, ask AI where to go for a night out or what activities would be enjoyable for everyone. AI can consider each member's personality to suggest an option that pleases the whole group.</li>
                <li><strong>Conflict Resolution:</strong> In a relationship group, you might seek advice on dealing with conflicts, such as financial disagreements. AI can analyze both partners' profiles to offer neutral, balanced advice.</li>
            </ul>

            <h3>Gifting Made Easy</h3>
            <p>
                AI can also help with choosing gifts. By understanding someone's personality report, AI can suggest gifts that align with their interests and preferences, ensuring your present is both thoughtful and well-received.
            </p>
            <p>
                The possibilities are endless when you combine human curiosity with AI's analytical power. By asking the right questions, you can uncover insights that are both enlightening and practical.
            </p>
        </Card>
    );
};

export default AboutAIGeneratedContent;
