import React from 'react';
import Slide from './Slide';
import Similr from '../Similr/Similr';
import { Card } from 'antd';

const SimilrTableSlide: React.FC = () => {
  const titleStyle: React.CSSProperties = {
    fontSize: '24px', // Bigger font size for the title
    textAlign: 'center', // Center the title text
    fontWeight: 'bold', // Make the title text bold
    padding: '10px',
  };

  return (
    <Slide>
      <Card title={<div style={titleStyle}>Similr Table</div>} bordered={false} style={{ width: '100%', maxWidth: '800px', margin: 'auto', overflow: 'hidden' }}>
        <Similr />
      </Card>
    </Slide>
  );
};

export default SimilrTableSlide;
