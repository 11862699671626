// components/FooterComponent.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const FooterComponent: React.FC = () => {
    return (
        <footer style={{ 
            textAlign: 'center', 
            padding: '20px', 
            marginTop: 'auto', // Push footer to the bottom
            backgroundColor: 'rgb(0, 21, 41)', // Dark background
            color: 'white' // Light text for dark background
        }}>
            © 2024 Similr.ai. All rights reserved. <Link to="/termsconditions" style={{ color: 'rgba(255, 255, 255, 0.65)' }}>Terms & Conditions</Link>
        </footer>
    );
};

export default FooterComponent;
