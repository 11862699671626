import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Typography, Button, Row, Col, Card, Input, Table, Tooltip, message, Popconfirm, Modal, Form, Select } from 'antd';
import { CloseOutlined, CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SortOrder } from 'antd/es/table/interface';

const { Title } = Typography;

interface TagInfo {
    name: string;
    description: string;
    group_type: string;
    is_private: boolean;
    is_owner: boolean;
    invitation_code: string;
    usernames: string[];
}

const fetchTags = async (): Promise<TagInfo[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/tags/?only_privates=true`, {
        method: 'GET',
        headers: {
        'Authorization': `Token ${authToken}`,
        'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const createGroup = async ({ name, description, group_type }: { name: string, description: string, group_type: string }): Promise<void> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/tags/create/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, description, group_type })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create group');
    }
};

const deleteGroup = async (groupName: string): Promise<void> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/tags/delete/`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: groupName })
    });

    if (!response.ok) {
        throw new Error('Failed to create group');
    }
};

const leaveGroup = async (groupName: string): Promise<void> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/tags/leave/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: groupName })
    });

    if (!response.ok) {
        throw new Error('Failed to create group');
    }
};

const joinGroup = async (invitationCode: string) => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const response = await fetch(`${backendUrl}api/tags/join/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: invitationCode })
    });

    if (!response.ok) {
        throw new Error('Failed to join group');
    }
}

const deleteInvitationCode = async (groupName: string) => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const response = await fetch(`${backendUrl}api/tags/delete-code/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: groupName })
    });

    if (!response.ok) {
        throw new Error('Failed to join group');
    }
};

const createNewInvitationCode = async (groupName: string) => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const response = await fetch(`${backendUrl}api/tags/new-code/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: groupName })
    });

    if (!response.ok) {
        throw new Error('Failed to join group');
    }
};

interface Group {
    name: string;
    description: string;
    memberCount: number;
}

interface GroupsProps {
    setSelectedGroup: (group: Group | null) => void;
}

const Groups: React.FC<GroupsProps> = ({setSelectedGroup}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const queryClient = useQueryClient();
    const { data: tags, isLoading } = useQuery('tags', fetchTags);
    const [newGroupName, setNewGroupName] = useState('');    
    const [invitationCode, setInvitationCode] = useState('');
    
    // Mutation for adding user to a group
    const addGroupMutation = useMutation(joinGroup, {
        onSuccess: () => {
            // Handle success (e.g., show success message, refetch tags)
            queryClient.invalidateQueries('tags');
            queryClient.invalidateQueries('groups');
            queryClient.invalidateQueries('groupMembers');
            setSelectedGroup(null);
            setInvitationCode(''); // Reset input field
        },
        onError: (error) => {
            // Handle error (e.g., show error message)
            message.error('Invitation Code does not exist.');
        }
    });

    const handleJoinGroup = () => {
        addGroupMutation.mutate(invitationCode);
    };

    const { mutate } = useMutation(createGroup, {
        onSuccess: () => {
            // On success, invalidate and refetch the tags query to update the list
            queryClient.invalidateQueries('tags');
            queryClient.invalidateQueries('groups');
            queryClient.invalidateQueries('groupMembers');
            setSelectedGroup(null);
            setNewGroupName(''); // Reset input field
            // Additional success logic (like showing a success message)
        },
        onError: (error: any) => {
            // Error handling logic (like showing an error message)
            message.error('Group with this name already exists.  Group name must be globally unique.');
        },
    });

    const showModal = () => {
        form.setFieldsValue({ name: newGroupName }); // Set the group name in the modal form
        setIsModalVisible(true);
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };
    
    const handleCreateGroup = async () => {
        try {
            const values = await form.validateFields();
            mutate(values); // Use mutate function with form values
            setIsModalVisible(false);
            form.resetFields();
            setNewGroupName(''); // Reset group name input field on the main page
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };
    
    const deleteGroupMutation = useMutation(deleteGroup, {
        onSuccess: () => {
            setSelectedGroup(null);
            queryClient.invalidateQueries('tags');
            queryClient.invalidateQueries('groups');
            queryClient.invalidateQueries('groupMembers');
            message.success('Group successfully deleted.');
        },
        onError: () => {
            message.error('Failed to delete group.');
        }
    });

    const leaveGroupMutation = useMutation(leaveGroup, {
        onSuccess: () => {
            setSelectedGroup(null);
            queryClient.invalidateQueries('tags');
            queryClient.invalidateQueries('groups');
            queryClient.invalidateQueries('groupMembers');
            message.success('Successfully left the group.');
        },
        onError: () => {
            message.error('Failed to leave group.');
        }
    });

    const handleDeleteGroup = (groupName: string) => {
        deleteGroupMutation.mutate(groupName);
    };

    const handleLeaveGroup = (groupName: string) => {
        leaveGroupMutation.mutate(groupName);
    };

    // Function to copy invitation code to clipboard
    const handleCopyCode = (code: string) => {
        navigator.clipboard.writeText(code)
            .then(() => {
                // Show success message
                message.success('Code copied to clipboard!');
            })
            .catch(err => {
                // Show error message in case of a failure
                console.error('Failed to copy: ', err);
                message.error('Failed to copy code.');
            });
    };

    const handleDeleteInvitationCode = (groupName: string) => {
        deleteInvitationCode(groupName).then(() => {
            message.success('Invitation code deleted.');
            queryClient.invalidateQueries('tags');
        }).catch((error) => {
            message.error('Failed to delete invitation code.');
        });
    };
    
    const handleCreateNewCode = (groupName: string) => {
        createNewInvitationCode(groupName).then(() => {
            message.success('New invitation code created.');
            queryClient.invalidateQueries('tags');
        }).catch((error) => {
            message.error('Failed to create new invitation code.');
        });
    };
    

    // Define table columns
    const columns = [
        {
            title: 'Group Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: TagInfo, b: TagInfo) => a.name.localeCompare(b.name), // Sort by name
            defaultSortOrder: 'ascend' as SortOrder, // Default sort by name in ascending order
        },
        {
            title: 'Type',
            dataIndex: 'group_type',
            key: 'group_type',
            sorter: (a: TagInfo, b: TagInfo) => a.group_type.localeCompare(b.group_type),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            // If descriptions can be long, consider using a Tooltip or a Modal to display them
            render: (text: string) => (
                <Tooltip title={text}>
                    {text?.length > 30 ? `${text.substring(0, 30)}...` : text}
                </Tooltip>
            ),
        },
        {
            title: 'Invitation Code',
            dataIndex: 'invitation_code',
            key: 'invitation_code',
            render: (text: string, record: TagInfo) => {
                if (record.is_owner) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {text ? (
                                <>
                                    <Input value={text} readOnly style={{ width: '120px', marginRight: '10px' }} />
                                    <Tooltip title="Copy Code">
                                        <Button 
                                            icon={<CopyOutlined />} 
                                            onClick={() => handleCopyCode(text)} 
                                            type="primary" 
                                        />
                                    </Tooltip>
                                    <Popconfirm
                                        title="Are you sure you want to delete this code?"
                                        onConfirm={() => handleDeleteInvitationCode(record.name)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Tooltip title="Delete Code">
                                            <Button 
                                                icon={<DeleteOutlined />} 
                                                type="default" 
                                                style={{ marginLeft: '5px', color: 'red', borderColor: 'red' }}
                                            />
                                        </Tooltip>
                                    </Popconfirm>
                                </>
                            ) : (
                                <>
                                    <span style={{ marginRight: '10px' }} >None</span>
                                    <Tooltip title="Create Code">
                                        <Button 
                                            icon={<PlusOutlined />} 
                                            onClick={() => handleCreateNewCode(record.name)} 
                                            type="primary" 
                                        />
                                    </Tooltip>
                                </>
                            )}
                        </div>
                    );
                }
                return null;
            }
        },
        {
            title: 'Owner',
            dataIndex: 'is_owner',
            key: 'is_owner',
            render: (isOwner: boolean) => isOwner ? 'Yes' : 'No',
            sorter: (a: TagInfo, b: TagInfo) => a.is_owner === b.is_owner ? 0 : a.is_owner ? -1 : 1, // Sort by owner status
        },
        {
            title: 'Members',
            key: 'usernames',
            render: (record: TagInfo) => (
                <div>
                    <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                        {record.usernames.slice(0, 2).map(username => (
                            <li key={username} style={{ margin: '5px 0' }}>{username}</li>
                        ))}
                        {record.usernames.length > 2 && (
                            <Tooltip
                                title={
                                    <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                                        {record.usernames.map(username => (
                                            <li key={username} style={{ margin: '5px 0' }}>{username}</li>
                                        ))}
                                    </ul>
                                }
                            >
                                <li style={{ color: 'rgb(22, 119, 255)', margin: '5px 0', cursor: 'pointer' }}>{record.usernames.length} members</li>
                            </Tooltip>
                        )}
                    </ul>
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (record: TagInfo) => record.is_owner ? (
                <Popconfirm
                    title="Are you sure you want to delete this group?"
                    onConfirm={() => handleDeleteGroup(record.name)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button 
                        type="default" 
                        icon={<DeleteOutlined />} 
                        style={{ color: 'red', borderColor: 'red' }}
                    >
                        Delete
                    </Button>
                </Popconfirm>
            ) : (
                <Popconfirm
                    title="Are you sure you want to leave this group?"
                    onConfirm={() => handleLeaveGroup(record.name)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button 
                        type="default" 
                        icon={<CloseOutlined />} 
                        style={{ color: 'red', borderColor: 'red' }}
                    >
                        Leave
                    </Button>
                </Popconfirm>
            ),
        }
    ];

    return (
        <>
        <Card bordered={false} style={{ width: '1000px', margin: 'auto', overflow: 'hidden' }}>
            {/* <Alert
                closable
                type='info'
                message="Share everyone's results in Private Groups!"
                description={(
                    <>
                        <p>
                            View your friends' and family's results by using groups.
                            Create a group and then share the invitation code.
                        </p>
                        <Button type="primary" onClick={goToPrivateGroupSlide}>Go to Result Group Slide</Button>
                    </>
                )}
            /> */}
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <Title level={4}>Create New Group</Title>
                    <Input
                        placeholder="Enter group name"
                        value={newGroupName}
                        onChange={(e) => setNewGroupName(e.target.value)}
                        style={{ width: '200px', marginRight: '10px' }}
                    />
                    <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
                        Create
                    </Button>
                </Col>
                <Col span={12}>
                    <Title level={4}>Add Yourself to a Group</Title>
                    <Input
                        placeholder="Enter invitation code"
                        value={invitationCode}
                        onChange={(e) => setInvitationCode(e.target.value)}
                        style={{ width: '170px', marginRight: '10px' }}
                    />
                    <Button type="primary" icon={<PlusOutlined />} onClick={handleJoinGroup}>
                        Add
                    </Button>
                </Col>
            </Row>
            <Row gutter={[12, 12]} style={{ marginTop: '30px' }}>
                <Col span={24}>
                    {!isLoading ? (
                        <Table 
                            dataSource={tags} 
                            columns={columns} 
                            rowKey="name" 
                            pagination={false} 
                            locale={{ emptyText: 'You are in no groups.' }} // Custom empty message
                        />
                    ) : (
                        <p>Loading...</p>
                    )}
                </Col>
            </Row>
        </Card>
        <Modal
            title="Create New Group"
            visible={isModalVisible}
            onOk={handleCreateGroup}
            onCancel={handleCancel}
            okText="Create"
            cancelText="Cancel"
        >
            <Form form={form} layout="vertical" name="createGroupForm">
            <Form.Item
                name="name"
                label="Group Name"
                rules={[{ required: true, message: 'Please input the group name!', max: 100 }]}
            >
                <Input placeholder="Enter group name" />
            </Form.Item>
            <Form.Item
                name="group_type"
                label="Group Type"
                rules={[{ required: true, message: 'Please select the group type!' }]}
            >
                <Select placeholder="Select a group type">
                    <Select.Option value="Family">Family</Select.Option>
                    <Select.Option value="Friends">Friends</Select.Option>
                    <Select.Option value="Relationship">Relationship</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Please input a description!', max: 255 }]}
            >
                <Input.TextArea placeholder="Enter a description" />
            </Form.Item>
            </Form>
        </Modal>
      </>
    );
};

export default Groups;