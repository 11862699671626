import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { Card, Button, List, Modal, Typography, Divider, Spin, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AuthContext } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';

const { confirm } = Modal;
const { Title, Text } = Typography;

interface UserInfo {
    name: string;
    email: string;
    createdAt: string;
}

interface Transaction {
    id: number | string;
    timestamp: string;
    amount: number;
    description: string;
}

interface AccountInfo {
    userInfo: UserInfo;
    transactions: Transaction[];
}

const fetchAccountInfo = async (): Promise<AccountInfo> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/account-info/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};


const AccountPage = () => {
    const { data: accountInfo, isLoading } = useQuery<AccountInfo>('accountInfo', fetchAccountInfo);
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure delete your account?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone and will erase all your data.',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No, cancel',
            onOk() {
                return new Promise((resolve, reject) => {
                    fetch(`${process.env.REACT_APP_BACKEND_URL}api/delete-account/`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Token ${localStorage.getItem('authToken')}`,
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => {
                        if (response.ok) {
                            message.success('Account successfully deleted');
                            logout();
                            navigate('/'); // Redirect to the root or landing page
                            resolve(null);
                        } else {
                            throw new Error('Failed to delete account');
                        }
                    })
                    .catch(error => {
                        message.error(error.message);
                        reject();
                    });
                });
            },
        });
    };

    if (isLoading || !accountInfo) {
        return <Spin size='large' style={{ marginTop: '50px' }}/>;
    }

    return (
        <div style={{ padding: '0', minWidth: '800px', margin: 'auto' }}>
            <Title style={{ textAlign: 'center' }} level={2}>Your Account</Title>
            <Card title="User Information" style={{ marginBottom: '20px', width: '100%' }}>
                <p><Text strong>Name:</Text> {accountInfo.userInfo.name}</p>
                <p><Text strong>Email:</Text> {accountInfo.userInfo.email}</p>
                <p><Text strong>Account Created:</Text> {accountInfo.userInfo.createdAt}</p>
                <Button style={{ marginTop: '20px',}} type="primary" danger onClick={showDeleteConfirm}>
                Delete Account
            </Button>

            </Card>
            <Card title="Transaction History" style={{ marginBottom: '20px', width: '100%' }}>
                <List
                    dataSource={accountInfo.transactions}
                    renderItem={(item: Transaction) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={item.timestamp}
                                description={
                                    <>
                                        <span style={{ fontWeight: 'bold', color: item.amount >= 0 ? 'green' : 'red' }}>
                                            {item.amount >= 0 ? 'Credit' : 'Deduction'}
                                        </span>
                                        {`: ${new Intl.NumberFormat('en-US').format(Math.abs(item.amount))} - ${item.description}`}
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <Divider />
        </div>
    );
};

export default AccountPage;