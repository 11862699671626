import React from 'react';
import GroupContentSection from './GroupContentSection';
import UserContentSection from './UserContentSection';

interface CustomContentSectionProps {
    content: string;
    groupName: string;
}

const ContentSection: React.FC<CustomContentSectionProps> = ({ content, groupName }) => {
    return (
        <>
            {content === "Group Info" ?
                <GroupContentSection groupName={groupName}/> :
                <UserContentSection groupName={groupName} username={content} />}
        </>
    );
};

export default ContentSection;
