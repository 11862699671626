import React, { useState } from 'react';
import { List, Image, Spin, Tabs } from 'antd';
import { useQuery } from 'react-query';
import RankingsSection from './RankingsSection';
import Top10Section from './Top10Section';
import GroupAISection from './GroupAISection';
import SimilrSection from './SimilrSection';

interface MemberInfo {
    username: string;
    fullname: string;
    headline1: string;
    paragraph1: string;
    image_url: string;
    num_questions_answered: number;
}

const fetchGroupInfo = async (groupName: string, overviewType: string): Promise<MemberInfo[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/groups/info/?tag=${encodeURIComponent(groupName)}&overview_type=${encodeURIComponent(overviewType)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok while fetching group info');
    }
    return response.json();
};

interface GroupContentSectionProps {
    groupName: string;
}

const GroupContentSection: React.FC<GroupContentSectionProps> = ({ groupName }) => {
    const [activeTab, setActiveTab] = useState('groupAI');
    const { data: members, isLoading } = useQuery(['groupInfo', groupName, activeTab], () => fetchGroupInfo(groupName, activeTab));

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '100px' }}>
                <Spin size="large" />
            </div>
        );
    }

    const sortedMembers = members?.sort((a, b) => a.username.localeCompare(b.username));

    const renderMemberList = () => (
        <List
            itemLayout="vertical"
            dataSource={sortedMembers}
            renderItem={member => (
                member.headline1 === 'No data for selected type' ? (
                    <List.Item>
                        <div style={{ marginBottom: '16px' }}>
                            {member.fullname ? member.fullname : member.username} has not generated this report yet.
                        </div>
                    </List.Item>
                ) : (
                    <List.Item>
                        <div style={{ marginBottom: '16px' }}>
                            <h3 style={{ margin: 0 }}>{member.headline1}</h3>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            {member.image_url && (
                                <div>
                                    <Image
                                        width={175}
                                        src={member.image_url}
                                        style={{ borderRadius: '8px', marginRight: '16px' }}
                                    />
                                    <h3 style={{ marginTop: '10px', textAlign: 'center' }}>{member.fullname} ({member.num_questions_answered})</h3>
                                </div>
                            )}
                            <div style={{ marginTop: '0', marginLeft: '20px' }}>
                                {member.paragraph1 && <p>{member.paragraph1}</p>}
                            </div>
                        </div>
                    </List.Item>
                )
            )}
        />
    );
    
    const tabItems = [
        {
            label: "Group AI",
            key: "groupAI",
            children: <GroupAISection groupName={groupName} />,
        },
        {
            label: "Similr",
            key: "similr",
            children: <SimilrSection members={members || []} />, // Assuming 'members' contains the list of group members
        },
        {
            label: "Rankings",
            key: "rankings",
            children: <RankingsSection groupName={groupName} />,
        },
        {
            label: "Top 10",
            key: "top10",
            children: <Top10Section groupName={groupName} />,
        },
        {
            label: "Personalities",
            key: "personality",
            children: renderMemberList(),
        },
        {
            label: "Careers",
            key: "career",
            children: renderMemberList(),
        },
        {
            label: "Matchmakers",
            key: "matchmaker",
            children: renderMemberList(),
        },
        {
            label: "D&D Character",
            key: "dnd",
            children: renderMemberList(),
        },
        {
            label: "Animal",
            key: "animal",
            children: renderMemberList(),
        },
    ];

    return (
        <div style={{ paddingLeft: 20 }}>
            <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems} />
        </div>
    );
};

export default GroupContentSection;
