import React, { useState } from 'react';
import SlideshowContainer from './SlideshowContainer';
import WelcomeSlide from './WelcomeSlide';
import SimilrChartSlide from './SimilrChartSlide';
import OverviewSlide from './OverviewSlide';
import InsightSlide from './InsightSlide';
import CompatibilitySlide from './CompatibilitySlide';
import FeedbackSlide from './FeedbackSlide';
import ProductSlide from './ProductSlide';
import { useParams } from 'react-router-dom';
import SimilrTableSlide from './SimilrTableSlide';

const SlideshowPage = () => {
  const { slideNumber } = useParams();
  const slideIndex = parseInt(slideNumber || '0', 0);
  const [currentSlide, setCurrentSlide] = useState(slideIndex);

  // Assuming you have the username available
  const username = localStorage.getItem('username') || "user";

  return (
    <SlideshowContainer currentSlide={currentSlide} setCurrentSlide={setCurrentSlide}>
      <WelcomeSlide username={username} />
      <InsightSlide insightCategory="Archetype" title="Top 3 Archetypes" isPositive={true} />
      <InsightSlide insightCategory="Motivation" title="Top 3 Motivations" isPositive={true} />
      <InsightSlide insightCategory="General Personality" title="Top 3 General Traits" isPositive={true} />
      <InsightSlide insightCategory="Personality" title="Bottom 10 Traits" isPositive={false} />
      <InsightSlide insightCategory="Personality" title="Top 10 Traits" isPositive={true} />
      <InsightSlide insightCategory="General Career" title="Top 3 Career Areas" isPositive={true} />
      <InsightSlide insightCategory="Career" title="Bottom 10 Careers" isPositive={false} />
      <InsightSlide insightCategory="Career" title="Top 10 Careers" isPositive={true} />
      <InsightSlide insightCategory="Famous Person" title="Bottom 10 Famous Similarities" isPositive={false} />
      <InsightSlide insightCategory="Famous Person" title="Top 10 Famous Similarities" isPositive={true} />
      <InsightSlide insightCategory="Personal Transportation" title="Top 3 Personal Transportation" isPositive={true} />
      <InsightSlide insightCategory="Hogwarts House" title="Hogwarts House" isPositive={true} />
      <InsightSlide insightCategory="D&D Class" title="D&D Class" isPositive={true} />
      <OverviewSlide overviewType="personality" title="Personality Summary" />
      <OverviewSlide overviewType="career" title="Career Advice"/>
      <OverviewSlide overviewType="matchmaker" title="Matchmaker"/>
      <OverviewSlide overviewType="dating_profile" title="Dating Profile"/>
      <OverviewSlide overviewType="dnd" title="Your D&D Character"/>
      <OverviewSlide overviewType="animal" title="What Animal Are You?"/>
      <SimilrTableSlide />
      <SimilrChartSlide />
      <CompatibilitySlide />
      <ProductSlide />
      <FeedbackSlide />
    </SlideshowContainer>
  );
};

export default SlideshowPage;
