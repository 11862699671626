import React, { useState } from 'react';
import { Alert, Col, Row, Tabs } from 'antd';
import Groups from './Groups';
import AboutGroups from './AboutGroups'; // Import the new component
import GroupOverview from './GroupOverview';
import MemberList from './MemberList';
import ContentSection from './ContentSection';
import { useQuery } from 'react-query';

const { TabPane } = Tabs;

interface Group {
    name: string;
    description: string;
    memberCount: number;
}

interface Member {
    username: string;
    fullname: string;
    num_choices: number;
}

const fetchGroups = async (): Promise<Group[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/tags/?only_privates=true`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok while fetching groups');
    }
    return response.json();
};

const fetchGroupMembers = async (groupName: string): Promise<Member[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/groups/list-members/?tag=${encodeURIComponent(groupName)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok while fetching recommendations');
    }
    return response.json();
};

const GroupsPage = () => {
    const [activeTabKey, setActiveTabKey] = useState("1"); // Default to "Your Groups"

    const { data: groups, isLoading: isLoadingGroups } = useQuery('groups', fetchGroups, {
        onSuccess: (data) => {
            if (data.length === 0) {
                setSelectedGroup(null);
            } else {
                if (!selectedGroup) {
                    setSelectedGroup(data[0]);
                }
            }
        }
    });
    
    const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
    const [selectedContent, setSelectedContent] = useState('Group Info');

    // Load members for the selected group
    const { data: members, isLoading: isLoadingMembers } = useQuery(
        ['groupMembers', selectedGroup?.name],
        () => fetchGroupMembers(selectedGroup?.name || ''),
        { enabled: !!selectedGroup } // Enable query only if selectedGroup is not null
    );
    
    const handleTabChange = (key: string) => {
        setActiveTabKey(key); // Update the active tab key state
    };

    const handleGroupTabChange = (key: string) => {
        const foundGroup = groups?.find(group => group.name === key);
        setSelectedGroup(foundGroup || null);
    };

    const handleMemberClick = (memberUsername: string) => {
        setSelectedContent(memberUsername); // Update selected content to the clicked member
    };

    const handleGroupClick = () => {
        setSelectedContent('Group Info'); // Update selected content to 'group'
    };

    if (isLoadingGroups) {
        return <div>Loading...</div>; // Adjust as needed for your loading indicator
    }

    
    // Check if the user is not in any groups
    if (!isLoadingGroups && groups && groups.length === 0) {
        return (
            <div>
                <Alert
                showIcon
                    message='You are not currently in any groups. Please go to the "Manage" tab to create a group or add yourself to an existing group.'
                />
                <Tabs defaultActiveKey="2" onChange={handleTabChange} activeKey={activeTabKey} style={{ width: '1000px' }}>
                    <TabPane tab="Your Groups" key="1" />
                    <TabPane tab="Manage" key="2">
                        <Groups setSelectedGroup={setSelectedGroup} />
                    </TabPane>
                    <TabPane tab="About" key="3">
                        <AboutGroups />
                    </TabPane>
                </Tabs>
            </div>
        );
    }

    return (
        <Tabs defaultActiveKey="1" onChange={handleTabChange} activeKey={activeTabKey} style={{ width: '1000px' }} >
            <TabPane tab="Your Groups" key="1">
                {groups && (
                    <Tabs 
                        defaultActiveKey={groups[0]?.name} 
                        onChange={handleGroupTabChange} 
                        type="card"
                    >
                        {groups.map(group => (
                            <TabPane tab={group.name} key={group.name} />
                        ))}
                    </Tabs>
                )}
                {selectedGroup && (
                    <Row>
                        <Col span={6}>
                            <GroupOverview group={selectedGroup} />
                            {isLoadingMembers ? (
                                <div>Loading members...</div>
                            ) : (
                                <MemberList
                                    members={members || []}
                                    onMemberClick={handleMemberClick}
                                    onGroupClick={handleGroupClick}
                                />
                            )}
                        </Col>
                        <Col span={18}>
                            <ContentSection content={selectedContent} groupName={selectedGroup.name} />
                        </Col>
                    </Row>
                )}
            </TabPane>
            <TabPane tab="Manage" key="2">
                <Groups setSelectedGroup={setSelectedGroup} />
            </TabPane>
            <TabPane tab="About" key="3">
                <AboutGroups />  {/* Add the new tab content */}
            </TabPane>
        </Tabs>
    );
};

export default GroupsPage;
