import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Spin, Select, List, Tooltip, Col, Row } from 'antd';

const { Option } = Select;

interface UserRanking {
    user_name: string;
    similarity_score: number;
}

interface RankingCategory {
    rec_id: number; // Add rec_id to interface
    rec_title: string;
    rec_description: string;
    users_ranked: UserRanking[];
}

const fetchCategories = async (): Promise<string[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/categories/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });
  
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
  };  

const fetchRankings = async (groupName: string, category: string): Promise<RankingCategory[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/groups/rankings/?tag=${encodeURIComponent(groupName)}&category=${encodeURIComponent(category)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok while fetching rankings');
    }
    return response.json();
};

interface RankingsSectionProps {
    groupName: string;
}

const RankingsSection: React.FC<RankingsSectionProps> = ({ groupName }) => {
    const [selectedCategory, setSelectedCategory] = useState('General Personality');
    const { data: rankingCategories, isLoading } = useQuery(['rankings', groupName, selectedCategory], () => fetchRankings(groupName, selectedCategory));
    const { data: categories } = useQuery('categories', fetchCategories);

    const handleCategoryChange = (value: string) => {
        setSelectedCategory(value);
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '100px' }}>
                <Spin size="large" />
            </div>
        );
    }

    // Sort the rankingCategories by rec_title (with handling for potential undefined value)
    const sortedRankingCategories = rankingCategories ? [...rankingCategories].sort((a, b) =>
        a.rec_title.localeCompare(b.rec_title)
    ) : [];


    return (
        <div>
            <Select
                style={{ width: 200, marginBottom: 20 }}
                onChange={handleCategoryChange}
                placeholder="Select Category"
                value={selectedCategory}
            >
                {categories?.sort((a, b) => a.localeCompare(b)).map(category => (
                    <Option key={category} value={category}>{category}</Option>
                ))}
            </Select>
            <Row gutter={[16, 16]}>
                {sortedRankingCategories.map(category => (
                    <Col xs={24} md={12} lg={6} key={category.rec_id}>
                        <Tooltip title={category.rec_description}>
                            <h4>{category.rec_title}</h4>
                        </Tooltip>
                        <List
                            dataSource={category.users_ranked}
                            renderItem={(user: UserRanking, index) => (
                                <List.Item.Meta
                                    title={
                                        <span style={{ fontWeight: 'normal' }}>
                                            {`${index + 1}. ${user.user_name} (${Math.round(user.similarity_score * 1000)})`}
                                        </span>
                                    }
                                />
                            )}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default RankingsSection;