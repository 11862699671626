import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('authToken'));
    const [username, setUsername] = useState<string | null>(localStorage.getItem('username'));
    const [name, setName] = useState<string | null>(localStorage.getItem('name'));
    const isLoggedIn = Boolean(authToken);
    const [credit_balance, setCreditBalance] = useState<number>(0);

    useEffect(() => {
        setAuthToken(localStorage.getItem('authToken'));
        setUsername(localStorage.getItem('username'));
        setName(localStorage.getItem('name'));
    }, []);

    // Fetch and update credit balance, wrapped in useCallback to prevent unnecessary re-renders
    const updateCreditBalance = useCallback(async () => {
        if (!authToken) return; // Early return if authToken is not available
        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const response = await axios.get(`${backendUrl}api/credit-balance/`, {
                headers: { 'Authorization': `Token ${authToken}` }
            });
            if (response.data) {
                setCreditBalance(response.data.credit_balance);
                setName(response.data.name);
            }
        } catch (error) {
            console.error("Error fetching credit balance:", error);
        }
    }, [authToken]); // authToken is a dependency of this useCallback

    // Call updateCreditBalance when authToken changes
    useEffect(() => {
        if (authToken) {
            updateCreditBalance();
        }
    }, [authToken, updateCreditBalance]);

    const login = (username: string, password: string): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await axios.post(`${backendUrl}api/api-token-auth/`, {
                    username,
                    password,
                });

                if (response.data && response.data.token) {
                    localStorage.setItem('authToken', response.data.token);
                    localStorage.setItem('username', username);
                    setAuthToken(response.data.token);
                    setUsername(username); // update username state

                    resolve();
                } else {
                    reject(new Error("The response did not contain a token"));
                }
            } catch (err: any) {
                if (err.response && err.response.status === 400) {
                    reject(new Error("Username/password don't match"));
                } else {
                    reject(new Error("An error occurred while logging in"));
                }
            }
        });
    };

    const signup = async (username: string, password: string, name: string) => {
        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const response = await axios.post(`${backendUrl}api/signup/`, {
                username,
                password,
                name
            });

            if (response.data && response.data.token) {
                localStorage.setItem('authToken', response.data.token);
                localStorage.setItem('username', username);
                localStorage.setItem('name', name);
                setAuthToken(response.data.token);
                setUsername(username);
            } else {
                // Handle error: the response did not contain a token
            }
        } catch (err) {
            // Handle error: the request failed, e.g. due to network error or 500 status
        }
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('username');
        localStorage.removeItem('name');
        setAuthToken(null);
        setUsername(null);
        setName(null);
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, username, name, login, logout, signup, credit_balance, updateCreditBalance }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
