import React, { ReactNode, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Segmented, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../../App.css'; // Ensure the styles are imported

const slideTitles = [
    "Welcome",
    "Top 3 Archetypes",
    "Top 3 Motivations",
    "Top 3 General Traits",
    "Bottom 10 Personality Traits",
    "Top 10 Personality Traits",
    "Top 3 Career Areas",
    "Bottom 10 Careers",
    "Top 10 Careers",
    "Bottom 10 Famous Similarities",
    "Top 10 Famous Similarities",
    "Top 3 Personal Transportation",
    "Hogwarts House",
    "D&D Class",
    "Personality Summary",
    "Career Advice",
    "Matchmaker",
    "Dating Profile",
    "D&D Character",
    "Your Animal",
    "Similr Table",
    "Similr Chart",
    "Compatibility",
    "Recommendations",
    "Feedback"
];

interface SlideshowContainerProps {
    children: ReactNode;
    currentSlide: number;
    setCurrentSlide: (slide: number) => void;
}

const SlideshowContainer: React.FC<SlideshowContainerProps> = ({ children, currentSlide, setCurrentSlide }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const totalSlides = React.Children.count(children);
    
    // Update currentSlide based on URL path when component mounts or URL changes
    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const slideIndexFromUrl = parseInt(pathSegments[pathSegments.length - 1]);
        const slideIndex = slideIndexFromUrl - 1; // Subtract 1 to convert to 0-based index
        if (!isNaN(slideIndex) && slideIndex >= 0 && slideIndex < totalSlides) {
            setCurrentSlide(slideIndex);
        }
    }, [location.pathname, setCurrentSlide, totalSlides]);

    const goToSlide = (value: number | string) => {
        const slideNumber = typeof value === 'string' ? parseInt(value, 10) : value;
        if (!isNaN(slideNumber) && slideNumber >= 0 && slideNumber < totalSlides) {
            setCurrentSlide(slideNumber);
            navigate(`/results/${slideNumber + 1}`, { replace: true }); // Add 1 to display user-friendly slide number
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <Button
                    icon={<LeftOutlined />}
                    size="small"
                    disabled={currentSlide === 0}
                    onClick={() => goToSlide(currentSlide - 1)}
                />
                <Segmented
                    className="responsive-segmented"
                    size="small"
                    options={slideTitles.map((title, index) => ({
                        label: <Tooltip title={title}><span>{index + 1}</span></Tooltip>,
                        value: index,
                    }))}
                    value={currentSlide}
                    onChange={goToSlide}
                    style={{ marginLeft: '10px', marginRight: '10px' }}
                />
                <Button
                    icon={<RightOutlined />}
                    size="small"
                    disabled={currentSlide === totalSlides - 1}
                    onClick={() => goToSlide(currentSlide + 1)}
                />
            </div>
            <div>
                {React.Children.toArray(children)[currentSlide]}
            </div>
        </div>
    );
};

export default SlideshowContainer;