import React, { useState } from 'react';
import { Card, Modal, Image, message, Button, Table, Popconfirm, Tooltip } from 'antd';
import { useQuery } from 'react-query';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EyeOutlined, ShareAltOutlined } from '@ant-design/icons';
import ShareContentModal from './ShareContentModal';

const overviewTypeMapping: { [key: string]: string } = {
    animal: "Animal",
    dnd: "D&D Character",
    dating_profile: "Dating Profile",
    career: "Career",
    matchmaker: "Matchmaker",
    personality: "Personality"
};

interface ContentItem {
    key: number;
    id: number;
    created_at: string;
    content_type: string;
    content_url: string | null;
    content_text: string | null;
    userOverviewPairs: string; // Combined string of user overview pairs
    parameters: {
        contentType: string;
        instructions: string;
        userOverviewPairs: Array<{ fullname: string, overview_type: string, username: string }>;
    };
    shared: string;
    shared_with_groups: Array<{ name: string }>;
}

const fetchCustomGens = async (): Promise<ContentItem[]> => {
    const authToken = localStorage.getItem('authToken');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const response = await fetch(`${backendUrl}api/custom-gen-list/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const CustomGenList = () => {
    const [selectedContent, setSelectedContent] = useState<ContentItem | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [selectedItemForSharing, setSelectedItemForSharing] = useState<ContentItem | null>(null);

    const { data: contentList, isError, error } = useQuery('customGensList', fetchCustomGens, {
        select: data => data.map(item => ({
            ...item,
            key: item.id,
            shared: item.shared_with_groups.length > 0 ? item.shared_with_groups.map(group => group.name).join(', ') : 'None', // Add shared groups info
            userOverviewPairs: item.parameters.userOverviewPairs.map(p => `${p.fullname}: ${overviewTypeMapping[p.overview_type]}`).join(', ')
        }))
    });
    
    // Function to show share modal
    const showShareModal = (content: ContentItem) => {
        setSelectedItemForSharing(content);
        setIsShareModalVisible(true);
    };

    // Function to confirm deletion
    const confirmDelete = (contentId: number) => {
        // Implement the deletion logic here
        console.log(`Deleting content with ID: ${contentId}`);
    };


    const showModal = (content: ContentItem) => {
        setSelectedContent(content);
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    // Function to map content type to a pretty format
    const mapContentTypeToPretty = (contentType: string): string => {
        const mapping: Record<string, string> = {
            'image': 'Image',
            'writeup': 'GPT-3.5',
            'writeup-gpt4': 'GPT-4'
        };
        return mapping[contentType] || contentType;
    };

    // Function to format and display content type
    const displayContentType = (contentType: string): string => {
        return mapContentTypeToPretty(contentType);
    };

    // Function to format and display created at date
    const displayCreatedAt = (createdAt: string): string => {
        return `Created: ${formatCreatedAt(createdAt)}`;
    };
    
    const formatCreatedAt = (dateString: string) => {
        return moment(dateString).format('ddd MM/DD/YYYY h:mm A'); // Add day of the week
    };

    const columns: ColumnsType<ContentItem> = [
        {
            title: 'Type',
            dataIndex: 'content_type',
            key: 'content_type',
            render: (text: string) => mapContentTypeToPretty(text),
            sorter: (a: ContentItem, b: ContentItem) => a.content_type.localeCompare(b.content_type),
            filters: [
                { text: 'Image', value: 'image' },
                { text: 'GPT-3.5', value: 'writeup' },
                { text: 'GPT-4', value: 'writeup-gpt4' },
            ],
            onFilter: (value, record: ContentItem) => record.content_type.indexOf(value.toString()) === 0,
        },
        {
            title: 'Instructions',
            dataIndex: 'parameters',
            key: 'instructions',
            render: (parameters: ContentItem['parameters'], record: ContentItem) => (
                <Tooltip title="View Details">
                    <span 
                        onClick={() => showModal(record)}
                        style={{ cursor: 'pointer' }}
                    >
                        {parameters.instructions}
                    </span>
                </Tooltip>
            ),
            sorter: (a: ContentItem, b: ContentItem) => a.parameters.instructions.localeCompare(b.parameters.instructions),
        },
        {
            title: 'Write Ups Used',
            dataIndex: 'userOverviewPairs',
            key: 'userOverviewPairs',
            sorter: (a: ContentItem, b: ContentItem) => a.userOverviewPairs.localeCompare(b.userOverviewPairs),
        },
        {
            title: 'Shared',
            dataIndex: 'shared',
            key: 'shared',
            render: (text: string) => text,
            sorter: (a: ContentItem, b: ContentItem) => a.shared.localeCompare(b.shared),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: string) => formatCreatedAt(text),
            sorter: (a: ContentItem, b: ContentItem) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(), // Sort by descending order
            defaultSortOrder: 'ascend', // Set the default sort order to descending
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_: any, record: ContentItem) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="View Details">
                        <Button type="link" icon={<EyeOutlined />} onClick={() => showModal(record)} style={{ marginRight: 8 }} />
                    </Tooltip>
                    <Tooltip title="Share">
                        <Button type="link" icon={<ShareAltOutlined />} onClick={() => showShareModal(record)} style={{ marginRight: 8 }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => confirmDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip title="Delete">
                            <Button style={{ color: "red" }} type="link" icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    if (isError) {
        message.error(`Error: ${error}`);
    }

    return (
        <>
            <Table columns={columns} dataSource={contentList} style={{ width: '100%' }} />
            {selectedContent && (
                <Modal
                    title={
                        <>
                            <strong>{displayContentType(selectedContent.content_type)}</strong>
                            <span style={{ fontWeight: 400 }} >{`: ${selectedContent.parameters.instructions}`}</span>
                        </>
                    }
                    visible={isModalVisible}
                    onCancel={handleModalCancel}
                    footer={null}
                    width={700} // Set a specific width for the modal
                >
                    <div style={{ marginBottom: '20px' }}>
                        <p>{selectedContent.userOverviewPairs}</p>
                        <p><em>{displayCreatedAt(selectedContent.created_at)}</em></p>
                    </div>
                    {selectedContent.content_type === 'image' && selectedContent.content_url && (
                        <Image src={selectedContent.content_url} alt="Custom Image" style={{ maxWidth: '100%' }} />
                    )}
                    {selectedContent.content_type !== 'image' && (
                        <Card style={{ textAlign: 'left' }} bodyStyle={{ paddingTop: 0 }} bordered={false}>
                            <ReactMarkdown className="markdown">{selectedContent.content_text}</ReactMarkdown>
                        </Card>
                    )}
                </Modal>
            )}
            {selectedItemForSharing && (
                <ShareContentModal
                    contentId={selectedItemForSharing?.id}
                    isVisible={isShareModalVisible}
                    onClose={() => setIsShareModalVisible(false)}
                />
            )}
        </>
    );
};

export default CustomGenList;
