import React from 'react';
import { Card, Avatar } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

interface GroupOverviewProps {
    group: {
        name: string;
        description: string;
        memberCount: number;
    };
}
  
const GroupOverview: React.FC<GroupOverviewProps> = ({ group }) => {
  
    return (
        <Card bordered={false} style={{ textAlign: 'center', marginBottom: 20 }}>
            <Avatar size={100} icon={<TeamOutlined />} style={{ marginBottom: '10px' }} />
            <h2 style={{ margin: '10px 0' }}>{group.name}</h2>
            <div>{group.description}</div>
        </Card>
    );
};

export default GroupOverview;
