import React from 'react';
import { Card, Col, Row, Typography, Badge } from 'antd';
import WrappedCheckoutComponent from './WrappedCheckoutComponent';

const { Title, Text } = Typography;

const BuyCreditsPage = () => {
    const creditPackages = [
        { credits: 300, price: 2, details: ['15 images', '30 GPT-4 write ups', '300 GPT-3.5 write ups'], stripePriceId: process.env.REACT_APP_STRIPE_PRICE_ID_300_CREDITS },
        { credits: 1000, price: 5, details: ['50 images', '100 GPT-4 write ups', '1,000 GPT-3.5 write ups'], stripePriceId: process.env.REACT_APP_STRIPE_PRICE_ID_1000_CREDITS },
        { credits: 2500, price: 10, details: ['125 images', '250 GPT-4 write ups', '2,500 GPT-3.5 write ups'], stripePriceId: process.env.REACT_APP_STRIPE_PRICE_ID_2500_CREDITS },
        { credits: 6000, price: 20, details: ['300 images', '600 GPT-4 write ups', '6,000 GPT-3.5 write ups'], bestValue: true, stripePriceId: process.env.REACT_APP_STRIPE_PRICE_ID_6000_CREDITS }
    ];
    
    return (
        <div style={{ background: '#F5F5F5', padding: '30px' }}>
            <Title level={1} style={{ textAlign: 'center', fontSize: '36px', marginBottom: '40px' }}>Buy Credits</Title>
            <Row gutter={[16, 16]} justify="center">
                {creditPackages.map((pkg, index) => (
                    <Col span={6} key={index}>
                        {pkg.bestValue ? (
                            <Badge.Ribbon text="Best Value" color="volcano" placement='start'>
                                <Card 
                                    title={`${pkg.credits.toLocaleString()} Credits`} 
                                    bordered={false} 
                                    headStyle={{ marginTop: '20px', fontSize: '20px', textAlign: 'center' }}
                                    style={{ width: '240px', height: '400px', display: 'flex', flexDirection: 'column' }}
                                >
                                    <div style={{ flexGrow: 1, textAlign: 'center' }}>
                                        {pkg.details.map((detail, idx) => (
                                            <React.Fragment key={idx}>
                                                <Text strong style={{ fontSize: '15px' }}>{detail}</Text>
                                                {idx < pkg.details.length - 1 && <Text strong style={{ display: 'block', margin: '10px 0' }}>- or -</Text>}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center'}}>
                                        <Text style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>${pkg.price}</Text>
                                    </div>
                                    <WrappedCheckoutComponent priceId={pkg.stripePriceId || ''} />
                                </Card>
                            </Badge.Ribbon>
                        ) : (
                            <Card 
                                title={`${pkg.credits.toLocaleString()} Credits`} 
                                bordered={false} 
                                headStyle={{ marginTop: '20px', fontSize: '20px', textAlign: 'center' }}
                                style={{ width: '240px', height: '400px', display: 'flex', flexDirection: 'column' }}
                            >
                                <div style={{ flexGrow: 1, textAlign: 'center' }}>
                                    {pkg.details.map((detail, idx) => (
                                        <React.Fragment key={idx}>
                                            <Text strong style={{ fontSize: '15px' }}>{detail}</Text>
                                            {idx < pkg.details.length - 1 && <Text strong style={{ display: 'block', margin: '10px 0' }}>- or -</Text>}
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center'}}>
                                    <Text style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>${pkg.price}</Text>
                                </div>
                                <WrappedCheckoutComponent priceId={pkg.stripePriceId || ''} />
                            </Card>
                        )}
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default BuyCreditsPage;
